import {Line, Pie} from "react-chartjs-2";
import {
    dataPie,
    FilterDate,
    filtersDate, optionsClienteFaturas,
    optionsFaturas,
    ResponseDataResume,
    ValueFilter
} from "./index";
import React, {useEffect, useState} from "react";
import {getApi} from "../../../apis/backendApis";
import {CButton, CCol, CForm, CRow} from "@coreui/react";
import Select from "react-select";
import "./style.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from "chart.js";
import {retrieveToken} from "../../../services/tokenVerify";
ChartJS.register(
    Title,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    ArcElement
);

const ResumoDashboard = (props:any) => {

    const getInitialFilter = () => {
        return {
            value: 2,
            label: 'Menor Igual'
        };
    };
    const currentYear = new Date().getFullYear();
    const [anosData, setAnosData] = useState<any[]>([]);
    const getInitialYears = () => {
        return {
            value: currentYear.toString(),
            label: currentYear
        };
    };
    const getMonths = () => {
        const months = [];
        for (let i = 0; i < 12; i++) {
            const date = new Date(0, i);
            const monthName = date.toLocaleString('default', {month: 'long'});
            months.push({
                value: i + 1,
                label: monthName.charAt(0).toUpperCase() + monthName.slice(1)
            });
        }
        return months;
    };
    const filter = getInitialFilter();
    const yearsInitial = getInitialYears();
    const currentMonth = new Date().getMonth() + 1;
    const monthOptions = getMonths();
    const [selectedYear, setSelectedYear] = useState(yearsInitial);
    const [selectedYearClienteFatura, setSelectedYearClienteFatura] = useState(yearsInitial);
    const [selectedYearCondicional, setSelectedYearCondicional] = useState(yearsInitial);
    const [selectedMonth, setSelectedMonth] = useState(monthOptions.find(month => month.value === currentMonth));
    const [selectedMonthCondicional, setSelectedMonthCondicional] = useState(monthOptions.find(month => month.value === currentMonth));
    const [loading, setLoading] = useState(false);
    const [dataHistorico, setDataHistorico] = useState<any>(null);
    const [dataClienteFaturamento, setDataClienteFaturamento] = useState<any>(null);
    const [selectedFilter, setSelectedFilter] = useState(filter);
    const [notDataClienteFaturas, setNotDataClienteFaturas] = useState(false);

    useEffect(() => {
        getData(true);
        let api = getApi();
        api.get('fatura/menor-ano').then((response) => {
            setAnosData(getList(response.data));
        });

    }, []);
    const getData = (firstAcess: boolean) =>{
        handleYearClienteFaturaChange(selectedYearClienteFatura)
        setLoading(true)
        const token = retrieveToken();
        const params = [{
            firstAcess: firstAcess,
            filters: {
                condicional: selectedFilter.value,
                //@ts-ignore
                mes: selectedMonth.value,
                ano: selectedYear.value
            },

        }];
        if(selectedFilter.value == ValueFilter.ENTRE) {
            const paramsCondicional: any = {
                filtersCondicional: {
                    //@ts-ignore
                    mes: selectedMonthCondicional.value,
                    //@ts-ignore
                    ano: selectedYearCondicional.value
                }
            };
            params.push(paramsCondicional);
        }
        let api = getApi();
        api.get('financeiro/resumo',{params}).then((response) => {
            setLoading(false)
            setDataHistorico(ResponseDataResume(response.data));
        });
    }
    const getList = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key]['name'];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    const formatOptions = (filters: FilterDate[]) => {
        return filters.map((filter: FilterDate) => ({
            value: filter.value,
            label: filter.description,
        }));
    };

    const handleChangeOptionFilters = (option: any) => {
        setSelectedFilter(option);
    };

    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
        setSelectedYearClienteFatura(selectedOption);
    };

    const handleYearClienteFaturaChange = (selectedOption: any) => {
        setSelectedYearClienteFatura(selectedOption);
        let params: any = {};
        params['filters'] = {
            'condicional': selectedFilter.value,
            //@ts-ignore
            'mes': selectedMonth.value,
            'ano': selectedYear.value
        };
        if (selectedFilter.value === ValueFilter.ENTRE) {
            params['filters-condicional'] = {
                //@ts-ignore
                'mes': selectedMonthCondicional.value,
                //@ts-ignore
                'ano': selectedYearCondicional.value
            };
        }
        let api = getApi();
        api.get("fatura/fatura-cliente", {params}).then((response) =>{
            if (response?.data?.['erro']){
                setNotDataClienteFaturas(true);
                setDataClienteFaturamento(null)
            }else{
                setNotDataClienteFaturas(false);
                setDataClienteFaturamento(response.data);
            }
        })
    };
    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
    };
    const handleYearChangeCondicional = (selectedOption: any) => {
        setSelectedYearCondicional(selectedOption);
    };

    const handleMonthChangeCondicional = (selectedOption: any) => {
        setSelectedMonthCondicional(selectedOption);
    };

    return (
        <div className={'container-fluid container-fluid container-fluid'}>
            <div className="filter-section ">
                <CForm>
                    <CRow>
                        {selectedFilter.value === ValueFilter.ENTRE ?
                            <div className={`col-12 mb-2`}>
                                <div className="form-group select-container">
                                    <Select
                                        options={formatOptions(filtersDate)}
                                        onChange={handleChangeOptionFilters}
                                        value={selectedFilter}
                                        placeholder="Filtro"
                                        closeMenuOnSelect={true}
                                        name="filter"
                                        className="filter-select-main"
                                    />
                                </div>
                            </div>
                            :
                            <div
                                className={`${selectedFilter.value === ValueFilter.ENTRE ? 'col-md-3' : 'col-md-4'}  col-12`}>
                                <div className="form-group select-container">
                                    <Select
                                        options={formatOptions(filtersDate)}
                                        onChange={handleChangeOptionFilters}
                                        value={selectedFilter}
                                        placeholder="Filtro"
                                        closeMenuOnSelect={true}
                                        name="filter"
                                        className="filter-select-main"
                                    />
                                </div>
                            </div>
                        }
                        {selectedFilter.value == ValueFilter.ENTRE && (
                            <>
                                <div
                                    className={`${selectedFilter.value === ValueFilter.ENTRE ? 'col-md-3' : 'col-md-4'}  col-sm-12`}>
                                    <div className="form-group  select-container">
                                        <Select
                                            placeholder="Mes"
                                            name="mes"
                                            options={monthOptions}
                                            value={selectedMonthCondicional}
                                            onChange={handleMonthChangeCondicional}
                                            className="filter-select-mes"
                                            styles={{menu: base => ({...base, zIndex: 9997})}}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${selectedFilter.value === ValueFilter.ENTRE ? 'col-md-3' : 'col-md-4'}  col-sm-12`}>
                                    <div className="form-group select-container">
                                        <Select
                                            options={anosData}
                                            value={selectedYearCondicional}
                                            onChange={handleYearChangeCondicional}
                                            placeholder="Ano"
                                            className="filter-select-ano"
                                            styles={{menu: base => ({...base, zIndex: 9997})}}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <div
                            className={`${selectedFilter.value === ValueFilter.ENTRE ? 'col-md-3' : 'col-md-4'}  col-sm-12`}>
                            <div className="form-group select-container">
                                <div className="form-group select-container">
                                    <Select
                                        placeholder="Mes"
                                        name="mes"
                                        options={monthOptions}
                                        value={selectedMonth}
                                        onChange={handleMonthChange}
                                        className="filter-select-mes"
                                        styles={{menu: base => ({...base, zIndex: 9997})}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${selectedFilter.value === ValueFilter.ENTRE ? 'col-md-3' : 'col-md-4'}  col-sm-12`}>
                            <div className="form-group select-container">
                                <Select
                                    options={anosData}
                                    onChange={handleYearChange}
                                    placeholder="Ano"
                                    name="ano"
                                    value={selectedYear}
                                    className="filter-select-ano"
                                />
                            </div>
                        </div>
                    </CRow>
                    <CRow>
                        <div className={'col-12 mt-2'}>
                            <div className="float-right ">
                                {!loading ? (
                                    <CButton onClick={() => getData(false)} color="primary"
                                             className="apply-filters-btn">
                                        Aplicar Filtros
                                    </CButton>
                                ) : (
                                    <div className="spinner-border applied-filters text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CRow>
                </CForm>
            </div>
            <div className="mt-3 bg-white">
                <CRow>
                    <div className={'col-12 shadow table-container'}>
                        {dataHistorico && dataHistorico.labels && (
                            <Line width="1200" height="450" id={'line-chart'}
                                  options={{...optionsFaturas, responsive: true}} data={dataHistorico}/>
                        )}
                    </div>
                </CRow>
                <div className={'container  bg-white'}>
                    {!notDataClienteFaturas ?
                        <CRow>
                            <div className="mt-4 col-12 font-2xl text-center">
                                Clientes x Faturamento
                            </div>
                            {Array.isArray(dataClienteFaturamento) && dataClienteFaturamento && dataClienteFaturamento?.map((value: any, index: number) => (
                                <div className={'col-xl-4 col-md-6 col-12 border mt-2   shadow'} key={index}>
                                    <div className={'text-center pt-2 font-2xl competencia'}>{value?.competencia}</div>
                                    <Pie id={'line-chart'}
                                         options={{...optionsClienteFaturas, responsive: true}} data={dataPie(value)}/>
                                </div>
                            ))}
                        </CRow>
                        :
                        <div className="no-records-container">
                            <CRow>
                                <CCol className="text-center">
                                    <h4>Nenhum registro encontrado</h4>
                                    <p>Por favor, tente novamente.</p>
                                </CCol>
                            </CRow>
                        </div>
                    }
                </div>
            </div>
            <div className={'m-2 footer-filter-client-status'}>
            <div><strong>Total de Registros: {dataClienteFaturamento?.length}</strong></div>
            </div>
        </div>
    );
};

export default ResumoDashboard;