import {ContentRoute} from "../../models/Route";
import ProgramacaoAgendamento from "./programacaoAgendamento";



const programacaoAgendaRouter: Array<ContentRoute> = [
    {
        'path':'/programacao',
        'description': 'AAAAAAAAAAAAAAAAA',
        'exact':true,
        'component': ProgramacaoAgendamento,
        'menu': false
    }
]

export default programacaoAgendaRouter;