import React, {useState, useEffect} from 'react';
import Select from "react-select";
import './../../scss/styles-programacao.scss';
import {
    CButton,
    CCol,
    CInput,
    CRow
} from "@coreui/react";
import {getApi} from "../../apis/backendApis";
import CIcon from "@coreui/icons-react";


const ProgramacaoAgendamento = () => {

    const [listSetor, setListSetor] = useState<any[]>([]);
    const [listUser, setListUser] = useState<any[]>([]);
    const [cliente, setCliente] = useState();
    const [responsavel, setResponsavel] = useState();
    const [setor, setSetor] = useState();
    const [checklistFinalizada, setChecklistFinalizada] = useState(false);
    const [values, setValues] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleFilterChange();
    }, []);

    const handleFilterChange = () => {
        setLoading(true);
        setValues([]);

        const filters = {
            nomeCliente: cliente? cliente: null,
            responsavel: responsavel ? responsavel : null,
            setor: setor ? setor : null,
            checklistFinalizada,
        };
        let valuesString = JSON.stringify(filters);
        let formData = new FormData();
        formData.append('data', valuesString);
        let api = getApi();
        api.request({
            url: '/checklist-agenda/list',
            method: "POST",
            data: formData
        }).then((response) => {
            setLoading(false)
            setValues(response.data);
        });
    };

    useEffect(() => {
        const api = getApi();
        api.get('/setor/suggest').then((response) => {
            setListSetor(getListSetor(response.data['setores']));
        });
        api.get("/usuarios/suggest").then((response)=>{
            let usuarios = [];
            if (response.data['usuarios']) {
                usuarios = getList(response.data['usuarios']);
            }
            setListUser(usuarios);
        })
    }, []);

    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }
    const getListSetor = (list: any) => {
        let data: any = [];
        list.forEach((setor : any) => {
            data.push({
                value: setor['id'],
                label:setor['nome']
            });
        });
        return data;
    }

    const handleFilterCliente = (option: any) =>{
        setCliente(option.target?.value);
    }
    const handleFilterResponsavel = (selectedOption: any) => {
        if (selectedOption?.value) {
            setResponsavel(selectedOption.value);
        } else {
            setResponsavel(undefined)
        }
    }
    const handleFilterSetor = (selectedOption: any) => {
        if (selectedOption?.value) {
            setSetor(selectedOption.value);
        } else {
            setSetor(undefined)
        }
    }

    return (
        <div className={'container-programacao-agendamento'}>
            <div className="filter-section">
                <CRow>
                    <CCol>
                        <div className="form-group">
                            <CInput
                                type="text"
                                id="nomeCliente"
                                placeholder="Nome do cliente"
                                onChange={handleFilterCliente}
                                className={'filter-select'}
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <div className="form-group">
                            <Select
                                options={listUser}
                                placeholder="Responsável"
                                closeMenuOnSelect={true}
                                name="usuarios"
                                onChange={handleFilterResponsavel}
                                isClearable={true}
                                className={'filter-select'}
                            />
                        </div>
                    </CCol>
                    <CCol>
                        <div className="form-group">
                            <Select
                                options={listSetor}
                                loadingMessage={() => "Carregando os setores"}
                                noOptionsMessage={() => "Não foi possível carregar os setores disponíveis"}
                                placeholder={'Setor'}
                                onChange={handleFilterSetor}
                                isClearable={true}
                                className={'filter-select'}
                            />
                        </div>
                    </CCol>
                    <CCol>

                            <CButton color="primary" className="btn btn-filter" onClick={handleFilterChange}>
                                <div className={'d-flex flex-row align-content-center justify-content-center'}>
                                    <div>Atualizar</div>
                                    {loading && (
                                        <div className={'ml-1'}>
                                            <span className="spinner-border text-white" role="status">
                                                <span className="sr-only"></span>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </CButton>

                    </CCol>
                </CRow>
            </div>

            <div className="grid-itens-programacao bg-white ">
                {values && values?.length > 0 ?
                    (<div>
                        {values?.map((date: any, indexDate: number) => (
                            <div key={indexDate} className={'d-flex flex-row border-bottom p-1'}>
                                <div className={'d-flex flex-column align-content-center date-container pt-2'}>
                                    <div className='d-flex flex-row align-content-center align-items-center w-100'>
                                        <div className='day p-1'>{date.day}</div>
                                        <div className='month flex-grow-1'>{date.month}</div>
                                    </div>
                                    <div className='week pl-3'>{date.week}</div>
                                </div>
                                <div className={'events-data d-flex flex-wrap'}>
                                    {date?.eventos.map((evento: any, index: number) => (
                                        <div key={indexDate + '-' + index} className={'card-event p-2 m-1 border'}>
                                            <div className={'d-flex align-items-center'}>
                                                <CIcon name={'cil-calendar'} className={'mr-1'}></CIcon>
                                                    {evento.horaInicio}
                                                    {evento.horaFim && (<span>&nbsp;às {evento.horaFim}</span>)}
                                            </div>
                                            {(evento.responsavel && evento.responsavel.length > 0) ?
                                                (<div className={'text-truncate'}>{evento.responsavel}</div>) :
                                                (<div className={'text-truncate text-danger'}>Não informado</div>)
                                            }

                                            <div className={'text-truncate text-black-50'}>{evento.setor}</div>
                                            <div className={'border-bottom'}></div>
                                            <div className={'d-flex text-truncate'}>
                                                {evento.cliente}
                                            </div>
                                            <div className={'text-truncate'}>
                                                <a target={'_blank'}
                                                   href={'#/task/executar/' + evento.taskId}>#{evento.taskId} - {evento.checklist}</a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>)
                     :
                    (<div className="no-records-container">
                        <CRow>
                            <CCol className="text-center m-4">
                                {!loading ? (
                                    <>
                                        <h4>Nenhum registro encontrado</h4>
                                        <div>Por favor, ajuste os filtros e tente novamente.</div>
                                    </>) :
                                    <>
                                        <h4>Buscando informações...</h4>
                                    </>
                                }
                            </CCol>
                        </CRow>
                    </div>)
                }
            </div>
        </div>
    )
}
export default ProgramacaoAgendamento;