import React, { useEffect, useState } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {getApi} from '../apis/backendApis';
import {
    AgPromise,
    ColumnApi,
    GridApi,
    GridReadyEvent,
    IDatasource,
    IDoesFilterPassParams,
    IFilterComp, IFilterParams,
    IGetRowsParams,
    RowNode
} from 'ag-grid-community';
import {CRow, CButton} from '@coreui/react';
import GridAction from './gridAction';
import {ActionProps} from './gridAction';
import Alert, {useAlertVisible} from "./alert";
import enumDescription from "../services/enumDescription";
import useLoading from "../services/useLoading";
import useAlert from "../services/useAlert";
import {CrossIcon} from "react-select/src/components/indicators";

interface Filter {
    name:string,
    op:string
    v:string,
    v2?:string,
}

interface PaginationConf{
    page?:number,
    limit:number,
    start:number,
    filter?:string,
    sort?:string
}

interface GridProps {
    rota      : string
    handleGridReady  ?: Function
    actions  ?: Array<ActionProps>
    children ?: any,
    title ?: string
}

type Props = GridProps &  AgGridReactProps;

const Grid = (props:Props) => {
    const [gridApi, setGridApi] = useState<GridApi>();
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
    const [selectedRows, setSelectedRow] = useState<any[]>([]);
    const [actions, setActions] = useState<any[]>([]);
    const [setLoading] = useLoading();
    const [showAlert] = useAlert();

    const onGridReady = (params:GridReadyEvent)=>{
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        const dataSource = createDataSource();
        gridApi?.setDatasource(dataSource);
    }

    const refresh = () => {
        gridApi?.onFilterChanged();
    }
    
    useEffect(()=>{
        if(gridApi){
            if(props.handleGridReady){
                props.handleGridReady(gridApi);
            }
        }
        const dataSource = createDataSource();
        gridApi?.setDatasource(dataSource);
    }, [gridApi])
    
    const getFilters = ()=>{
        let filters : Array<Filter> = [];
        if(gridApi?.getFilterModel()){
          let filterModelData = gridApi?.getFilterModel();
          for(let filterName in filterModelData){
            let filterModel = filterModelData[filterName];
            let operador = '=';
            switch (filterModel.type){
              case 'contains':
                operador = 'like';
                break;
              case 'lessThan':
                operador = '<';
                break;
              case 'lessThanOrEqual':
                operador = '<=';
                break;
              case 'greaterThan':
                operador = '>';
                break;
              case 'greaterThanOrEqual':
                operador = '>=';
                break;
              case 'notContains':
                operador = 'notlike';
                break;
              case 'equals':
                operador = '=';
                break;
              case 'notEqual':
                operador = '<>';
                break;
              case 'startWith':
                operador = 'inicialike';
                break;
              case 'endsWith':
                operador = 'terminalike';
                break;
              case 'inRange':
                operador = 'between';
                break;
               case 'in':
                  operador = 'contains';
                break;
            }

            let val  = filterModel.filter;
            let val2 = null;

            if(filterModel.dateFrom){
              val = filterModel.dateFrom;
            }

            if(filterModel.dateTo){
              val2 = filterModel.dateTo;
            }

            if(filterModel.filterTo){
               val2 = filterModel.filterTo;
            }

            let param:Filter = {
                "name":filterName,
                "v":val,
                "v2":val2,
                "op":operador
            };

            filters.push(param);
          }
        }
        return filters;
      }

    const createDataSource = () =>{
        const dataSource: IDatasource = {

            getRows: (params: IGetRowsParams) => {
                gridApi?.showLoadingOverlay();
                let page = gridApi?.paginationGetCurrentPage();
                if(page){
                    page++;
                }
                let sortData = [];
                let sorts = 0;
                for(let row in params.sortModel){                    
                    let data = params.sortModel[row];
                    sortData.push ({col:data.colId, sort:data.sort});
                    sorts++;
                }
                let filters = getFilters();
                let filter, sort;
                if(filters.length > 0){
                    filter = JSON.stringify(filters);
                }              
                if(sortData && sorts > 0){
                    sort = JSON.stringify(sortData);
                }
                let config:PaginationConf = {
                    filter,
                    page,
                    limit:20,
                    start:params.startRow,
                    sort
                };
                let endPoint = props.rota;
                let api = getApi();
                api.get(endPoint, {params: config}).then(response=>{
                    gridApi?.hideOverlay();
                    params.successCallback(
                        response.data.rows, 
                        response.data.total
                    );
                }).catch((error)=>{
                    showAlert({
                        title:'Erro',
                        msg: error.response.data.error,
                        details:error.response.data.trace,
                    });
                });
            }
        };

        return dataSource;
    }

    useEffect(()=>{
        if(gridApi){
            let newActions = props.actions?.map((action)=>{
                if(!action.onClickFunction && ['delete', 'post'].includes(action.type)){
                    action.onClickFunction = (rows, route)=>{
                        setLoading(true);
                        let api = getApi();

                        let id = rows[0].id;
                        let method:'post'|'delete' = 'post';
                        let url = route+`/${id}`;
                        if(action.type == 'delete'){
                            method = 'delete';
                        }
                        api.request(
                            {
                                method,
                                url
                            }
                        ).then((response)=>{
                                showAlert({
                                    msg: response.data.msg,
                                    toaster: true,
                                });
                            }).catch((error)=>{
                            showAlert({
                                title:'Erro',
                                msg: error.response.data.error,
                                details:error.response.data.trace,
                            });
                        }).finally(()=>{
                            gridApi?.setDatasource(createDataSource());
                            setLoading(false);
                        });

                    }
                }
                return action
            });
            if(newActions){
                setActions(newActions);
            }
        }
    }, [gridApi])

    const onSelectionChanged = ()=>{
        if(gridApi){
            let localSelectedRows = gridApi.getSelectedRows();
            setSelectedRow(localSelectedRows);
        }
    }

    const disableAction = (action:ActionProps)=>{
        let disableAction = action.type != 'normal' && !(selectedRows && selectedRows.length > 0 );

        if(!disableAction && action.validate){
            disableAction = !action.validate(selectedRows);
        }

        return disableAction;
    }

    return (
        <div className="ag-theme-alpine lg-12 ag-grid-padrao">
            {props.title && props.title.length > 0 &&
            <CRow className={'d-flex ml-0 mr-0 align-items-center'}>
                <div className={'flex-grow-1 text-left font-lg alert alert-info'}>{props.title}</div>
            </CRow>}
            <CRow className="d-flex ml-0 align-items-center">
                <div className={'flex-grow-1'}>
                    {actions && actions.map(action=>(
                            <GridAction disabled={disableAction(action)}  key={action.route} gridApi={gridApi} className={action.type == 'normal' ? "m-1 mr-4" : "m-1"} {...action} >{action.title}</GridAction>
                        )
                    )
                    }
                </div>
                <div className={'mr-4'}>
                    <CButton className={'btn-info m-1'} onClick={refresh}>Atualizar</CButton>
                </div>

            </CRow>    
            <AgGridReact
                {...props}
                pagination={true}
                paginationPageSize={20}
                rowModelType={'infinite'}
                cacheBlockSize={20}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                rowSelection={'single'}
                localeText= {{
                    // for number filter and text filter
                    notEqual: 'Diferente',
                    filterOoo: 'Filtro...',
                    lessThan: 'Menor',
                    greaterThan: 'Maior',
                    lessThanOrEqual: 'Menor ou igual',
                    greaterThanOrEqual: 'Maior ou igual',
                    inRange: 'No intervalo',
                    inRangeStart: 'De',
                    inRangeEnd: 'Até',
                    equals: 'Igual',
                    contains: 'Contém',
                    notContains: 'Não Contém',
                    startsWith: 'Inicia com',
                    endsWith: 'Termina com',
                    // filter conditions
                    andCondition: 'e',
                    orCondition: 'ou',
                    //rodapé
                    page: 'página',
                    more: '',
                    to: 'até',
                    of: 'de',
                    next: 'próxima',
                    last: 'última',
                    first: 'primeira',
                    previous: 'anterior',
                    loadingOoo: 'Carregando Dados...',
                }}
                >           
                {props.children}
            </AgGridReact>
        </div>
    );
};

export abstract class ListaMultiplaFilter implements IFilterComp{
    private gui: HTMLElement = document.createElement('div');
    private eFilterText: Element | null = null;
    private valueGetter?:(rowNode: RowNode) => any;
    private filterText:string | null = null;

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        return true;
    }

    getGui(): HTMLElement {
        return this.gui;
    }

    getModel(): any {
        return { filter: this?.filterText, type: 'in'};
    }

    init(params: IFilterParams): AgPromise<void> | void {

        this.valueGetter = params.valueGetter;
        this.filterText = null;
        this.setupGui(params);

        return undefined;
    }

    setupGui(params:IFilterParams)  {
        this.gui =  document.createElement('div');

        let options = this.getList().getList().map(item=>(
            `<option value="${item.value}"> ${item.description}</option>`
        ));

        this.gui.innerHTML =
            '<div style="padding: 4px; width: 200px;">' +
            '<div style="font-weight: bold;">Selecione</div>' +
            '<div>' +
            '<select multiple style="width: 100%; margin: 4px 0px 4px 0px;" id="filterText">'+
                options.join('')+
            '</select>'+
            '</div>' +
            '</div>';

        this.eFilterText = this.gui.querySelector('#filterText');
        if(this.eFilterText){
            // @ts-ignore
            this.eFilterText.addEventListener("change", listener);
        }

        var that = this;

        function listener(event:React.ChangeEvent<HTMLSelectElement>) {
            let values = [];
            let selectedOptions = event.target.selectedOptions;
            for(let i = 0; i< selectedOptions.length; i++){
                let item = selectedOptions.item(i);
                if(item){
                    values.push(item.value);
                }
            }
            that.filterText = values.join(',');
            params.filterChangedCallback();
        }
    }

    isFilterActive(): boolean {
        return this.filterText != null && this.filterText !== '';
    }

    setModel(model: any): void | AgPromise<void> {
        // @ts-ignore
        this.eFilterText.value = model?.value;

        let options: number[] = model?.value;
        if(options && options.length>0){
            options.map(option=>{
                let optionElement = this.gui.querySelector("#filterText option[value='" + option + "']");
                if(optionElement){
                    // @ts-ignore
                     optionElement.selected = true;

                }
            })

            this.filterText = options.join(',');
        }
    }

    abstract getList() : enumDescription
}

export default Grid;