import React, {useEffect, useState} from "react";
import {getApi} from "../../../../apis/backendApis";
import {CAlert, CButton, CCard, CCardBody, CCardHeader, CForm, CFormGroup, CLabel, CRow} from "@coreui/react";
import Select from "react-select";
import useCustomForm from "../../../../services/useCustomForm";
import CIcon from "@coreui/icons-react";
import useAlert from "../../../../services/useAlert";
import {useHistory} from "react-router-dom";
import {MesList} from "../../index";

const getYears = () => {
    const years = [];
    for (let i = 0; i < 7; i++) {
        years.push(new Date().getFullYear() - i);
    }
    let anos = [];
    for (let valor of years) {
        anos.push({
            value: valor,
            label: valor
        })
    }
    return anos;
}
const getMonth = () => {
    const months : any[] = [];
    MesList.getList().forEach((monthValue) => {
        months.push({
            value: monthValue.value,
            label: monthValue.description
        });
    })
    return months;
}


const RemeterFaturaForm = () => {

    let history = useHistory();
    const [showAlert] = useAlert();
    const [load, setLoad] = useState(true);
    const [listClientes, setListCLientes] = useState([]);
    const [response, setResponse] = useState({"errorsMessage": [],"successMessage": []});
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState({value: currentYear, label: currentYear});
    const yearOptions = getYears();
    const monthOptions = getMonth();
    const [selectedMonth, setSelectedMonth] = useState<null | any>(null);


    const initialValues = {
        cliente: [],
        mes: selectedMonth,
        ano: selectedYear.value
    };


    const {
        setValues,
        values,
        handleSubmit,
        handleSubmitValues,
        handleChange,
    } = useCustomForm(
        {
            initialValues,
            route: 'fechamentoFatura',
            onSubmit: values => {
                let newValues = {...values.values};
                handleSubmitValues(newValues, values.filesUpload)
            },
        }
    );

    useEffect(() => {
        let api = getApi();
        api.get('cliente/suggest').then((response) => {
            setListCLientes(getList(response.data['clientes']));
        })
    }, []);
    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }
    const getListFaturas = (list: any) => {
        let data: any = [];
        let monthNames: any = [];
        for (let i = 0; i < 12; i++) {
            const date = new Date(0, i);
            monthNames.push(date.toLocaleString('default', { month: 'long' }));
        }
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            Object.values(cliente).forEach((monthIndex: any) => {
                data.push({
                    label: monthNames[monthIndex - 1],
                    value: monthIndex
                });
            });
        });
        console.log(data)
        return data;
    }

    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
        values.ano = selectedOption.value
        setValues({...values})
        //carregarFaturas();
    };

    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
        values.mes = parseInt(selectedOption.value)
        setValues({...values})
    };

    const handleClient = (selectedOption: any) => {
        values.cliente = [];
        selectedOption.forEach((cliente : any) => {
            values.cliente.push(parseInt(cliente['value']));
        })
        setValues({...values})
    };

    const setCurrentMonth = (currentMonth: any) => {
        setSelectedMonth(currentMonth);
        if (!values.mes) {
            values.mes = currentMonth.value;
            setValues({...values});
        }
    }

    const remeterFatura = () => {
        setResponse({"errorsMessage": [],"successMessage": []});
        let erros = [];
        if(!values.mes){
            erros.push('Mês da Fatura');
        }
        if(!values.ano){
            erros.push('Ano da Fatura');
        }
        if(erros.length > 0){
            showAlert({
                title: 'Informe os campos obrigatórios',
                type: 'danger',
                msg: 'Campo(s) não preenchido(s): ' + erros.join(', ')
            });
        } else {
            setLoad(false);
            let valuesString = JSON.stringify(values);
            let formData = new FormData();
            let api = getApi();
            formData.append('data', valuesString);
            api.post('/fatura/enviar-documento', formData).then((response) => {
                setLoad(true);
                console.log();
                setResponse(response.data);
                showAlert({
                    title: 'Operação finalizada',
                    msg: response.data['message']
                });
            });
        }
    }

    return (
        <CForm>
            <CCard>
                <CCardHeader>
                    Envio de Faturas para Clientes
                </CCardHeader>
                <CCardBody>
                    <CAlert className={'alert alert-info'}>
                        Somente será enviados registro de fatura na situação de Faturada.<br/>
                        Se não selecionado um cliente sera enviado a todos os clientes que possuem uma fatura disponível para a comperência selecionada.<br/>
                        Enviaremos a NFS-e associada a fatura e os respectivos boletos.<br/>
                    </CAlert>
                    <CFormGroup>
                        <div className={'row'}>
                            <div className={'col-6'}>
                                <CLabel className={'text-danger'}> Ano da Fatura </CLabel>
                                <Select
                                    options={yearOptions}
                                    value={selectedYear}
                                    placeholder="Selecione o Ano"
                                    onChange={handleYearChange}
                                    loadingMessage={() => "Carregando os anos"}
                                    noOptionsMessage={() => "Não foi possível carregar os anos"}
                                    required
                                />
                            </div>
                            <div className={'col-6'}>
                                <CLabel className={'text-danger'}> Mês da Fatura </CLabel>
                                <Select
                                    options={monthOptions}
                                    value={selectedMonth}
                                    placeholder="Selecione o Mês"
                                    onChange={handleMonthChange}
                                    loadingMessage={() => "Carregando os Meses"}
                                    noOptionsMessage={() => "Não foi encontrada nenhuma fatura para este ano."}
                                    required
                                />
                            </div>
                        </div>
                    </CFormGroup>
                    <CFormGroup>
                        <CLabel> Selecione caso deseje um Cliente específico
                        </CLabel>
                        <Select
                            options={listClientes}
                            onChange={handleClient}
                            isMulti={true}
                            placeholder={'Enviar para todos os clientes'}
                            loadingMessage={() => "Carregando os clientes"}
                            noOptionsMessage={() => "Sem clientes disponíveis"}
                        />
                    </CFormGroup>
                    <CFormGroup>
                        {load ? (
                            <CButton className={'btn-info'} onClick={remeterFatura}>
                                <CIcon name={'cil-share'} /> &nbsp; Enviar Faturas para Clientes
                            </CButton>
                        ) : (
                            <CButton className={'btn-info '} disabled aria-disabled="true">
                             <span className="spinner-border spinner-grow-sm"
                                   role="status" aria-hidden="true"></span> &nbsp; Processando Envio...
                            </CButton>
                        )}
                    </CFormGroup>
                    {response["errorsMessage"].length > 0 && (
                        <CAlert className={'alert alert-danger'}>
                            {response["errorsMessage"].map((error,index) => <div key={'error-' + index} className={'p-1'}>{error}</div>)}
                        </CAlert>
                    )}
                    {response["successMessage"].length > 0 && (
                        <CAlert className={'alert alert-success'}>
                            {response["successMessage"].map((success,index) => <div key={'success-' + index} className={'p-1'}>{success}</div>)}
                        </CAlert>
                    )}

                </CCardBody>
            </CCard>
        </CForm>
    );
}

export default RemeterFaturaForm;