import  {ContentRoute} from "../../../models/Route";
import sprintList from "./sprintList";

export const dataBaseSprint = (dataResponseSprint: any) =>{
    const eixoX = [dataResponseSprint.map((sprint: any) => sprint['sprint'])];
    const data1 = [dataResponseSprint.map((totalHorasEstimadas: any) =>  totalHorasEstimadas['totalHorasEstimadas'])];
    const data2 = [dataResponseSprint.map((totalHorasExecucao: any) => totalHorasExecucao['totalHorasDesenvolvimento'])];
    const data3 = [dataResponseSprint.map((totalHorasExecucao: any) => totalHorasExecucao['totalHorasQualidade'])];
    const retorno = loadDataDashLinearSprint(eixoX, data1, data2, data3);
    return retorno;
}
export const dataBaseAtividade = (dataResponseRetornoAtividade: any) =>{
    const eixoX = [dataResponseRetornoAtividade.map((sprint: any) => sprint['sprint'])];
    const data1 = [dataResponseRetornoAtividade.map((totalAtividades: any) => totalAtividades['total'])];
    const data2 = [dataResponseRetornoAtividade.map((totalRetorno: any) => totalRetorno['totalRetorno'])];
    return loadDataDashLinearAtividade(eixoX, data1, data2, "Atividade");
}

export const loadDataDashLinearSprint = (eixoX: any, data1: any, data2: any, data3: any) => {
    const data = {
        labels: eixoX[0],
        datasets: [
            {
                label: 'Total Horas Estimadas',
                data: data1[0],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.8)',
            },
            {
                label: 'Total Horas Desenvolvimento' ,
                data: data2[0],
                borderColor: 'rgb(149,223,112)',
                backgroundColor: 'rgb(149,223,112)',
            },
            {
                label: 'Total Horas Qualidade' ,
                data: data3[0],
                borderColor: 'rgb(77,140,45)',
                backgroundColor: 'rgb(77,140,45)',
            },
        ],
    };
    return(data);
}

export const loadDataDashLinearAtividade = (eixoX: any, data1: any, data2: any, msg:string) => {
    const data = {
        labels: eixoX[0],
        datasets: [
            {
                label:   'Total de Atividade',
                data: data1[0],
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(19,225,107,0.8)',
            },
            {
                label: 'Total de Atividades Retornadas',
                data: data2[0],
                borderColor: 'rgb(8,236,106)',
                backgroundColor: 'rgba(255,128,0,0.8)',
            },
        ],
    };
    return(data);
}


export const optionsRetorno = {
    maintainAspectRatio: false,
    responsive: true,
    // scales: {
    //     x: {
    //         stacked: true,
    //     },
    //     y: {
    //         stacked: true,
    //     }
    // },
    layout: {

    },
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Gráfico de Atividades x Retornos Qualidade'
        },
    },
};

export const optionsHoras = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Gráfico de Horas x Execução'
        },
    }
};

export const resolutionScreen = () =>{
    const larguraTela = window.innerWidth
    var alturaTela = window.innerHeight
    if (larguraTela < 500 && alturaTela < 1000) {
        return true;
    } else {
        return false;
    }
}


export const sprints_content_routes: Array<ContentRoute> = [
    {
        'path': '/sprint',
        'description': 'Sprints',
        'exact': true,
        'component': sprintList,
        'menu': true
    }
]