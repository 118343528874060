import enumDescription from "../../services/enumDescription";
import {ContentRoute} from "../../models/Route";
import LogList from "./logList";
import TaskForm from "../task/taskForm";
import LogForm from "./logForm";

export enum SituacaoTypes {
    PENDENTE         = 1,
    EM_EXECUCAO      = 2,
    FINALIZADA       = 3,
    CANCELADA        = 4,
}

export const SituacaoList = new enumDescription(  [
    {   value: SituacaoTypes.PENDENTE, description: 'Pendente'    },
    {   value: SituacaoTypes.EM_EXECUCAO, description: 'Em Execução'    },
    {   value: SituacaoTypes.FINALIZADA, description: 'Finalizada'    },
    {   value: SituacaoTypes.CANCELADA, description: 'Cancelada'    },
]);

const log_content_routes: Array<ContentRoute> = [
    {
        'path':'/log',
        'description': 'Loglist',
        'exact':true,
        'component': LogList,
        'menu': true
    },
    {
        'path':'/log/visualizar/:id',
        'description': 'Destalhes do Log',
        'exact':true,
        'component': LogForm,
        'menu': false
    },
]

export default log_content_routes;