import {financeiro_content_routes } from './resultado';
import {ContentRoute} from "../../models/Route";
import enumDescription from "../../services/enumDescription";

const routes: Array<ContentRoute> = financeiro_content_routes

export enum SituacaoTypes {
    EM_ABERTO = 1,
    FECHADO   = 2,
    FATURADO  = 3,
    PAGO  = 4,
    CANCELADO = 5,
}

export enum SituacaoFaturaTypes {
    FECHADO = 1,
    FATURADO = 2,
    PAGO = 3,
    CANCELADO = 4,
    ENVIADO = 5,
    SITUACAO_EM_PROCESSAMENTO
}
export enum SituacaoFaturaDocTypes {
    PENDENTE = 1,
    SINCRONIZADO = 2,
    PRONTO_ENVIO = 4,
    CANCELADO = 3,
}
export enum TipoFaturaDocTypes {
    BOLETO= 1,
    NOTA_FISCAL  = 2,
}

export const SituacaoList = new enumDescription(  [
    {   value: SituacaoTypes.EM_ABERTO, description: 'Aberto'},
    {   value: SituacaoTypes.FECHADO, description: 'Fechado'},
    {   value: SituacaoTypes.FATURADO, description: 'Faturado'},
    {   value: SituacaoTypes.PAGO, description: 'Pago'},
    {   value: SituacaoTypes.CANCELADO, description: 'Cancelado'}
]);

export const SituacaoFaturaList = new enumDescription(  [
    {   value: SituacaoFaturaTypes.SITUACAO_EM_PROCESSAMENTO, description: 'Em Aberto'},
    {   value: SituacaoFaturaTypes.FECHADO, description: 'Fechado'},
    {   value: SituacaoFaturaTypes.FATURADO, description: 'Faturado'},
    {   value: SituacaoFaturaTypes.ENVIADO, description: 'Faturado e Enviado'},
    {   value: SituacaoFaturaTypes.PAGO, description: 'Pago'},
    {   value: SituacaoFaturaTypes.CANCELADO, description: 'Cancelado'}
]);

export const SituacaoFaturaDocumentoList = new enumDescription(  [
    {   value: SituacaoFaturaDocTypes.PENDENTE, description: 'Pendente'},
    {   value: SituacaoFaturaDocTypes.SINCRONIZADO, description: 'Sincronizado'},
    {   value: SituacaoFaturaDocTypes.PRONTO_ENVIO, description: 'Pronto Para Envio'},
    {   value: SituacaoFaturaDocTypes.CANCELADO, description: 'Cancelado'},

]);

export const TipoFaturaDocumentoList = new enumDescription(  [
    {   value: TipoFaturaDocTypes.NOTA_FISCAL, description: 'Nota Fiscal'},
    {   value: TipoFaturaDocTypes.BOLETO, description: 'Boleto'},
]);

export const MesList = new enumDescription(  [
    {   value: 1, description: 'Janeiro'},
    {   value: 2, description: 'Fevereiro'},
    {   value: 3, description: 'Março'},
    {   value: 4, description: 'Abril'},
    {   value: 5, description: 'Maio'},
    {   value: 6, description: 'Junho'},
    {   value: 7, description: 'Julho'},
    {   value: 8, description: 'Agosto'},
    {   value: 9, description: 'Setembro'},
    {   value: 10, description: 'Outubro'},
    {   value: 11, description: 'Novembro'},
    {   value: 12, description: 'Dezembro'}
]);

export const mesListFormater  = (params:any) =>{
    return MesList.getDescription(params.value);
}

export const situacaoListFormater = (params:any) =>{
    return SituacaoList.getDescription(params.value);
}

export const situacaoFaturaListFormater = (params:any) =>{
    return SituacaoFaturaList.getDescription(params.value);
}

export const SituacaoFaturaDocumento = (params:any) =>{
    return SituacaoFaturaDocumentoList.getDescription(params.value);
}

export const TipoFaturaDocumento = (params:any) =>{
    return TipoFaturaDocumentoList.getDescription(params.value);
}

export default routes;