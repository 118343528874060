import React, {useEffect, useState} from 'react';
import {getApi} from "../../../apis/backendApis";
import CIcon from '@coreui/icons-react';
import "./Styles.scss";
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CCollapse, CInput,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle, CNav, CNavItem, CNavLink,
    CRow, CTabContent, CTabPane, CTabs
} from "@coreui/react";
import {Bar} from 'react-chartjs-2';
import {dataBaseSprint, dataBaseAtividade, resolutionScreen, optionsHoras, optionsRetorno} from "./index";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import useAlert from "../../../services/useAlert";

ChartJS.register(
    Title,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const SprintList = (props: any) => {

    const [loadingData, setLoandingDados] = useState(false);
    const initialValues: any = [];
    const [showAlert] = useAlert();
    const [dataResponseSprint, setData] = useState(initialValues);
    const [dadosAtividadeMembros, setDadosAtividadeMembros] = useState(initialValues);
    const [dadosSprintMembros, setDadosSprintMembros] = useState(initialValues);
    const [dataResponseAtividade, setDataAtividade] = useState(initialValues);
    const [dataLinearAtividade, setDataLinearAtividade] = useState({initialValues, datasets: []});
    const [dataLinearSprint, setDataLinearSprint] = useState({initialValues, datasets: []});
    const [afetado, setAfetado] = useState(true);
    const [visible, setVisible] = useState<number | null>(-1);
    const [visible1, setVisible1] = useState<number | null>(-1);
    const [uptade, setUpdate] = useState(false);
    const [resultion, setResultion] = useState(false);
    const [indexI, setIndex] = useState<number | null>(0);
    const [showModal, setShowModal] = useState(false);
    const [dataResultLog, setDataResultLog] = useState(initialValues);

    let var1 = -1
    let var2 = -2

    const loadingoff = () => {
        setUpdate(false);
    }
    useEffect(() => {
        setAfetado(true);
        if (afetado) {
            setUpdate(true);
            atualizaGraficoSprint();
            atualizaGraficoAtividade();
            setResultion(resolutionScreen);
        }
    }, [afetado]);

    const updateData = () => {
        setAfetado(false)
    }

    const porcentagem = (parcial: any, total: any) => {
        if (parcial && total != null) {
            return Math.round((parcial * 100) / total);
        } else {
            return 0;
        }

    }

    const horasMais = (horasTrabalhadas: any, horasEstimadas: any) => {
        return Math.round(horasEstimadas - horasTrabalhadas);
    }
    const atualizaGraficoAtividade = () => {
        setDataLinearAtividade(initialValues)
        let api = getApi();
        api.get(`retorno-atividade/list/`).then((response) => {
            setDataAtividade(response.data)
            //@ts-ignore
            setDataLinearAtividade(dataBaseAtividade(response.data));
            loadingoff();
        }).catch(() => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }

    const atualizaGraficoSprint = () => {
        setDataLinearSprint(initialValues)
        let api = getApi();
        api.get(`sprints/list/`).then((response) => {
            setData(response.data)
            //@ts-ignore
            setDataLinearSprint(dataBaseSprint(response.data));
            loadingoff();
        }).catch((error) => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }

    const buscarSprintMembro = (sprint: any) => {
        setLoandingDados(true);
        let api = getApi();
        api.get(`busca-equipe/list/` + sprint).then((response) => {
            setDadosSprintMembros(response.data)
            setLoandingDados(false)
            loadingoff();
        }).catch((error) => {
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }

    const buscarAtividadeMembro = (sprint: any) => {
        setLoandingDados(true);
        let api = getApi();
        api.get(`retorno-atividade-membro/list/` + sprint).then((response) => {
            setDadosAtividadeMembros(response.data)
            setLoandingDados(false)
        }).catch((error) => {
            console.log(error + "testeeeee")
            showAlert({
                title: 'Não foi possível acessar a requisição.',
                // @ts-ignore
                msg: error.toString(),
                toaster: false,
            });
        });
    }


    const onClickDetalhes = (sprint: any, idUser: any) => {
        let api = getApi();
        api.get(`detalhes/list/` + sprint + `?membro=` + idUser).then((response) => {
            setDataResultLog(response.data);
            setShowModal(true);
        }).finally(() => {

        });
    }

    const onCloseModal = () => {
        setShowModal(false);
    }

    const accordionSprint = (index: number) => {
        setVisible(visible === index ? null : index)
    }
    const accordionAtividade = (index: number) => {
        setVisible1(visible1 == index ? null : index)
    }

    const AccordionDetalhes = (index: number) => {
        setIndex(indexI == index ? null : index)
    }

    return (<div className={' w-100 mt-2'}>
            <div className={'flex-row text-right'}>
                <CButton className={'btn-info m-1 '} onClick={updateData}>
                    {uptade && <span className="spinner-border spinner-border-sm mr-2" role="status"
                                     aria-hidden="true"></span>}
                    Atualizar
                </CButton>
            </div>
            <div className={'bg-white w-100 mt-2 '}>
                <div className={resultion ? '' : 'p-5 pt-lg-0 pb-lg-0 '}>
                    <Bar width="1200" height="350" options={optionsHoras} data={dataLinearSprint}></Bar>
                </div>
                {/*

                <div className={'text-muted execute-task-items col-md-12'}>
                    <br/>
                    <div className={"border-left font-lg border-right border-top mt-1 mb-3"}>
                        <div className={'row p-2'}>
                            <div className={'col-11 cursor-pointer font-weight-bold text-center'}
                                 onClick={() => AccordionDetalhes(var1)}>Detalhes do Gráfico de Horas do Sprint
                            </div>
                            <div onClick={() => AccordionDetalhes(var1)}
                                 className={'col-1 text-lg-right cursor-pointer font-weight-bold '}>
                                <CIcon name="cilChevronCircleDownAlt"></CIcon></div>
                        </div>
                        <div className='border-bottom'/>
                        <CCollapse className={''} show={indexI == var1}>
                            <CRow>
                                <div className="col-12">
                                    <div className=" row font-weight-bold border-bottom m-0">
                                        <div className={resultion ? 'text-center col-2 p-2 pt-4' : 'p-2 col-2'}>
                                            Sprint
                                        </div>
                                        <div
                                            className={resultion ? 'p-2 col-5 border-left text-center pt-4' : 'p-2 col-5 border-left text-center'}>
                                            Horas Estimadas
                                        </div>
                                        <div
                                            className={resultion ? 'p-2 col-5 border-left text-center pt-4' : 'p-2 col-5 border-left text-center'}>
                                            Horas Desenvolvidas
                                            <div
                                                className={"col-1 float-right text-right"}>h+</div>
                                        </div>
                                    </div>
                                </div>
                            </CRow>
                            {dataResponseSprint.map((result: any, index: number) => (
                                <div key={index}>
                                    <div className={'detalhes-sprint'} color="primary"
                                         onClick={() => buscarSprintMembro(result['sprint'])}>
                                        <div className="text-hover-sprints row border-bottom m-0 cursor-pointer"
                                             onClick={() => accordionSprint(index)}>
                                            <div className={'col-2 d-flex'}>
                                                <div className={'pt-2'}>
                                                    {result['sprint']}
                                                </div>
                                            </div>
                                            <div className={'p-2 col-5 text-center border-left'}>
                                                {parseFloat(result['totalHorasEstimadas']).toFixed(2)}
                                            </div>
                                            <div className={'p-2 col-5 text-center border-left'}>
                                                {parseFloat(result['totalHorasExecucao']).toFixed(2)}
                                                <div
                                                    className={"col-1 float-right pr-5"}>{horasMais(result['totalHorasEstimadas'], result['totalHorasExecucao'])}h+
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CCollapse className={''} show={visible == index}>
                                        <div className={loadingData ? "spinner-border" : ""}></div>
                                        {!loadingData &&
                                            <div className="col-12 p-1 ">
                                                {dadosSprintMembros && dadosSprintMembros.map((membro: any, indexMembro: number) => (
                                                    <div
                                                        className={((indexMembro % 2) === 0) ? 'row border-bottom m-0 detalhes bg-light ' : 'row border-bottom m-0 detalhes'}
                                                        key={indexMembro}>
                                                        <div className="col-12 align-content-around"
                                                        >
                                                            <div className={"border-right row"}>
                                                                <div
                                                                    className={resultion ? 'col-2 overflow-hidden col-2  border-right' : " col-2 text-left pl-5 border-right"}>
                                                                    {membro.nome}
                                                                </div>
                                                                <div className="col-5 text-center ">
                                                                    {membro.totalHorasEstimadas ? membro.totalHorasEstimadas : 'N/A'}
                                                                </div>
                                                                <div className="col-5 text-center border-left">
                                                                    {membro.totalHorasExecucao}
                                                                    <div className={"col-1 float-right text-right "}>
                                                                        {horasMais(membro.totalHorasEstimadas, membro.totalHorasExecucao)}h+
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </CCollapse>

                                </div>
                            ))}
                        </CCollapse>
                    </div>
                </div>
                */}
                <div className={resultion ? '' : 'p-5 pt-lg-0 pb-lg-0 '}>
                    <Bar width="1200" height="350" options={optionsRetorno} data={dataLinearAtividade}></Bar>
                </div>
                <div className={'text-muted execute-task-items col-md-12'}>
                    <br/>
                    <div className={"border-left font-lg border-right border-top mt-1 mb-3"}>
                        <div className={'row p-2'}>
                            <div className={'col-11 cursor-pointer font-weight-bold text-center'}
                                 onClick={() => AccordionDetalhes(var2)}>Detalhes Atividades x Retornos Qualidade
                            </div>
                            <div onClick={() => AccordionDetalhes(var2)}
                                 className={'col-1 text-lg-right cursor-pointer font-weight-bold '}>
                                <CIcon name="cilChevronCircleDownAlt"></CIcon></div>
                        </div>
                        <div className='border-bottom'/>
                        <CCollapse className={'collapse-var2'} show={indexI == var2}>
                            <div>
                                <CRow>
                                    <div className="col-12">
                                        <div className="row font-weight-bold border-bottom m-0">
                                            <div className={resultion ? 'text-center col-2 p-2 pt-4' : 'p-2 col-2'}>
                                                Sprint
                                            </div>
                                            <div className={resultion ? 'p-2 col-5 border-left text-center pt-4' : 'p-2 col-5 border-left text-center'}>
                                                Total de Atividades
                                            </div>
                                            <div className={resultion ? 'col-5 border-left text-center pt-4' : 'p-2 col-5 border-left text-center'}>
                                                Total de Atividades Retornadas
                                            </div>
                                        </div>
                                    </div>
                                </CRow>
                                {dataResponseAtividade.map((result: any, index: number) => (
                                    <div key={index}>
                                        <div className={'detalhes-retorno-atividade'} key={index} color="primary"
                                             onClick={() => accordionAtividade(index)}>
                                            <div className={"text-hover-sprints row border-bottom m-0 cursor-pointer"}
                                                 onClick={() => buscarAtividadeMembro(result['sprint'])}>
                                                <div className={'col-2 d-flex'}>
                                                    <div className={'pt-2 '}>
                                                        {result['sprint']}
                                                    </div>
                                                </div>
                                                <div className={'p-2 col-5 text-center border-left'}>
                                                    {result['total']}
                                                </div>
                                                <div className={'p-2 col-5 text-center border-left'}>
                                                    {result['totalRetorno']}&nbsp;
                                                    ({porcentagem(result['totalRetorno'], result['total'])}%)
                                                </div>
                                            </div>
                                        </div>
                                        <CCollapse className={''} show={visible1 == index}>
                                            <div className="col-12 p-1 ">
                                                <div className={loadingData ? "spinner-border" : ""}></div>

                                                {!loadingData &&
                                                    <div className="col-12 p-1 ">
                                                        {dadosAtividadeMembros && dadosAtividadeMembros.map((membro: any, indexMembro: number) => (
                                                            <div
                                                                className={((indexMembro % 2) === 0) ? 'row border-bottom m-0 detalhes bg-light' : 'row border-bottom m-0 detalhes'}
                                                                key={indexMembro}>
                                                                <div className="text-hover col-12 align-content-around"
                                                                     onClick={() => onClickDetalhes(result['sprint'], membro['id_membro'])}
                                                                     key={indexMembro}>
                                                                    <div className={"border-right row cursor-pointer"}>
                                                                        <div
                                                                            className={resultion ? 'col-2 overflow-hidden col-2  border-right' : " col-2 text-left pl-5 border-right"}>
                                                                            {membro.nome}
                                                                        </div>
                                                                        <div className="col-5 text-center ">
                                                                            {membro.taskTotais}
                                                                        </div>
                                                                        <div className="col-5 text-center border-left">
                                                                            {membro.taskRecusadas ? membro.taskRecusadas : 0}
                                                                            &nbsp;({porcentagem(membro.taskRecusadas, membro.taskTotais)}%)

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </CCollapse>
                                    </div>
                                ))}
                            </div>
                        </CCollapse>
                    </div>
                </div>
            </div>
            <div className={'p-4'}/>
            <CModal
                show={showModal}
                onClosed={onCloseModal}
                backdrop={true}
                color="info"
                size={'lg'}
            >
                <CModalHeader closeButton>
                    {dataResultLog.length > 0 && (
                        <CModalTitle className={"col-10"}>Detalhes Tasks
                            #{dataResultLog[0]['destino']}
                            <p className={'col-3 float-right text-sm-right font-italic font-sm'}>Sprint
                                #{dataResultLog[0]['name']}</p></CModalTitle>)}
                </CModalHeader>
                <CModalBody>
                    <div className={'row border-bottom border-top'}>
                        <div className={"col-3 text-center pt-1"} aria-hidden={true}>
                            Task
                        </div>
                        <div className={"p-2 col-3  border-left text-center pt-1"} aria-hidden={true}>
                            Quem Rejeitou
                        </div>
                        <div className={"p-2 col-3 border-left text-center pt-1"} aria-hidden={true}>
                            Vezes Rejeitada
                        </div>
                        <div className={"p-2 col-3 border-left text-center pt-1"} aria-hidden={true}>
                            Horas Trabalhadas
                        </div>
                    </div>
                    {dataResultLog.map((result: any, index: number) => (
                            <div
                                className={((index % 2) === 0) ? 'row border-bottom  detalhes bg-gray-100' : 'row border-bottom detalhes'}
                                key={index}>
                                <div className={"col-3 text-center pt-1"} aria-hidden={true}>
                                    <a href={`https://redmine.panoramasistemas.com.br/issues/${result.id_issue}`}
                                       target="_blank">#{result['id_issue']}</a>
                                </div>
                                <div className={" col-3 border-left"} aria-hidden={true}>
                                    {result['user_origem']}
                                </div>
                                <div className={" col-3 border-left"} aria-hidden={true}>
                                    {result['amount_recused']}
                                </div>
                                <div className={" col-3 border-left text-center text-hidden"} aria-hidden={true}>
                                    {parseFloat(result['horas_trabalhadas']).toFixed(2)}
                                </div>
                            </div>
                        )
                    )}
                </CModalBody>
            </CModal>
        </div>
    );
};
export default SprintList;