import React, {useState, useEffect} from 'react';
import {
    CFormGroup,CLabel,CInput,CCol,
    CListGroup, CListGroupItem, CRow
} from '@coreui/react';
import CIcon from "@coreui/icons-react";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import AsyncSelect from "react-select/async";
import {TaskFormData, ChecklistFormData, SituacaoList} from "./index";
import {getApi} from "../../apis/backendApis";
import Form, {FormApi} from "../../components/form";
import useCustomForm from '../../services/useCustomForm'
import useCurrentAction from "../../services/useCurrentAction";
import useLoading from "../../services/useLoading";
import Select from "react-select";

interface ChecklistListResponse {
    id: number,
    nome:string
}

const TaskForm = ()=>{
    const [formApi, setFormApi] = useState<FormApi>();
    const action = useCurrentAction();
    const [setLoading] = useLoading();
    const [listUsuarios, setListUsuarios] = useState([]);
    const [hideDrag, setHideDrag] = useState(false)

    const initialValues:TaskFormData = {
        cliente: {},
        descricao: '',
        taskChecklist:[]
    }

    useEffect(() => {
        let api = getApi();
        api.get("/usuarios/suggest").then((response)=>{
            let usuarios = [];
            if (response.data['usuarios']) {
                usuarios = getList(response.data['usuarios']);
            }
            setListUsuarios(usuarios);
        })
    }, []);

    const getList = (list: any) => {
        let data: any = [];
        Object.keys(list).forEach((key) => {
            const cliente = list[key];
            data.push({
                label: Object.values(cliente)[0],
                value: Object.keys(cliente)[0]
            });
        });
        return data;
    }

    const {
        values,
        setValues,
        handleChange,
        handleSubmit,
        handleSubmitValues,
        handleChangeSuggest,
    } = useCustomForm(
        {
            initialValues,
            route:'task',
            onSubmit: values => {
                let ordem = 1;
                let newValues = {...values.values};
                let taskChecklistOrdenada = newValues.taskChecklist.
                    filter((checklist:ChecklistFormData)=>(checklist.ativa)).
                        map((checklist:ChecklistFormData)=>{
                            return {
                                ...checklist,
                                ordem: ordem++,
                                nome: undefined,
                                draggId: undefined,
                                ativa: undefined
                            }
                        });


                //console.log({...newValues, taskChecklist: taskChecklistOrdenada})
                handleSubmitValues({...newValues, taskChecklist: taskChecklistOrdenada})
            },
            onLoadDadosCompleted : (data)=>{
                let newData = {...data, cliente: {value: data.cliente.id , label: data.cliente.pessoa.nome}}

                newData['taskChecklist'] = newData.taskChecklist.map((checklist:ChecklistFormData)=>{
                    return {...checklist, draggId: Math.random().toString()}
                })

                setValues(newData);
            }
        }
    );

    useEffect( () =>{
        if(action && action == 'add'){
            let api = getApi();
            let params = {
                filter: JSON.stringify(
                    [
                        {
                            "name":'ativo',
                            "v":1,
                            "op":'='
                        }
                    ]
                ),
                sort: JSON.stringify([
                    {
                        'col': 'ordem',
                        'sort': 'asc'
                    }
                ])
            };
            setLoading(true);
            api.get('checklist/list', {params}).then(response=>{
                let checklists = response.data.rows.map((checklist:ChecklistListResponse)=>{
                    let {id, nome} = checklist;
                    return {
                        checklist:id,
                        nome,
                        ativa:true,
                        draggId: Math.random().toString()
                    }
                });

                setValues({...values, taskChecklist: checklists})
            }).finally(()=>{
                setLoading(false);
            })
        }
    },[action])

    const promiseOptions = (inputValue:string):Promise<any> => {
        let api = getApi();
        return api.get(`/cliente/suggest/${inputValue}`).then(response=>response.data);
    }

    const isView = () => {
        return action === 'view';
    };

    const handleFormReady = (formApi:FormApi)=>{
        setFormApi(formApi);
    }

    const handleUsuario = (selectedOption: any) => {
        values.usuario = selectedOption.value
        setValues({...values});
    };

    const handleClickChecklist = (index:number)=>{
        let checkLists = [...values.taskChecklist];
        checkLists.splice(index, 1, {...checkLists[index], ativa: !checkLists[index].ativa})
        setValues({...values, 'taskChecklist': checkLists})
    }

    const onDragEnd = (result:DropResult) =>{
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const resultList = Array.from(values.taskChecklist);
        const [removed] = resultList.splice(result.source.index, 1);
        resultList.splice(result.destination.index, 0, removed);

        setValues({...values, taskChecklist: resultList});
    }

    return (
        <Form entity="Atividade" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            <CFormGroup>
                <CLabel htmlFor="cliente">Cliente</CLabel>
                <AsyncSelect isDisabled={isView()} required defaultOptions loadOptions={promiseOptions} onChange={newValue=>handleChangeSuggest(newValue, 'cliente')} value={values.cliente}>
                </AsyncSelect>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="descricao">Descrição</CLabel>
                <CInput readOnly={isView()} disabled={isView()} type="text" id="descricao" name="descricao" onChange={handleChange} value={values.descricao} required/>
            </CFormGroup>
            <CFormGroup>
                <CLabel htmlFor="cliente">Gestor do Projeto</CLabel>
                <Select
                    options={listUsuarios}
                    isDisabled={isView()}
                    onMenuOpen={()=>{setHideDrag(true)}}
                    onMenuClose={()=>{setHideDrag(false)}}
                    required defaultOptions
                    loadOptions={listUsuarios}
                    onChange={handleUsuario}
                    value={listUsuarios.find((usuario: any) => usuario.value == values['usuario']?.['id'])}>
                </Select>
            </CFormGroup>
            { isView() && (
                <CFormGroup>
                    <CRow>
                        <CCol className="col">
                            <CLabel htmlFor="situacao">Situação</CLabel>
                            <CInput readOnly={true} disabled={true} type="text" id="situacao" name="situacao" value={SituacaoList.getDescription(values.situacao)} required/>
                        </CCol>
                    </CRow>
                </CFormGroup>
            )}
            {!hideDrag &&
            <CFormGroup>
                <CLabel htmlFor="descricao">Checklists</CLabel>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="list"
                    >
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <CListGroup>
                                    {values.taskChecklist && values.taskChecklist.map((checklist:ChecklistFormData, index:number)=>(
                                        <Draggable
                                            draggableId={checklist.draggId}
                                            index={index}
                                            key={checklist.draggId}
                                            isDragDisabled={isView()}
                                        >
                                            {provided =>(
                                                <div
                                                    key={checklist.draggId}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <CListGroupItem
                                                        // key={checklist.draggId}
                                                        href="#"
                                                        active={values.taskChecklist[index].ativa && !isView()}
                                                        onClick={()=>{}}
                                                        disabled={isView()}
                                                    >
                                                        <CRow>
                                                            <CCol className="col-8" onClick={()=>handleClickChecklist(index)}>
                                                                {checklist.nome}
                                                            </CCol>
                                                            <CCol className="col-3">
                                                                <CInput type="date" name="previsao" id="previsao" onChange={event=>handleChange(event, index, 'taskChecklist')} value={values.taskChecklist[index].previsao}></CInput>
                                                            </CCol>
                                                            <CCol className="col-1 row justify-content-end align-content-center pr-0">
                                                                { !isView() && (
                                                                    <CIcon name="cil-apps" className="c-icon"></CIcon>
                                                                )}
                                                            </CCol>
                                                        </CRow>
                                                    </CListGroupItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </CListGroup>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </CFormGroup>
            }
        </Form>
    )
}

export default TaskForm;