import React, { useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Redirect,useLocation, useHistory} from "react-router-dom";
import {
    CHeader,
    CHeaderNav,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {clearToken} from "../../services/tokenVerify";
import {loggingOff} from "../../store/ducks/login/actions";
import {States} from "../../store/ducks/rootReducer";

const TheHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const loginState = useSelector((state:States)=> state.login);
    const [showRollback, setShowRollback] = useState(false);

    useEffect(()=>{
        setShowRollback(location.pathname  !== '/');
    },[location])

    const logout = () => {
        dispatch(loggingOff());
        clearToken();
        return (<Redirect to="/login"/>);
    }

    const rollback = ()=>{
        history.goBack();
    }

    const onClickInfo = ()=>{
        window.open(process.env.REACT_APP_HOST+'system_info/', '_blank');
    }

    return (
        <CHeader>
            <CHeaderNav className="px-3">
                {showRollback && (
                    <a href="#">
                        <CIcon name="cil-arrow-left" className="m-3" onClick={rollback}/>
                    </a>
                )}
            </CHeaderNav>
            <CHeaderNav className="d-md-down-none mr-auto">
            </CHeaderNav>
            <CHeaderNav className="px-3">
                Usuário: {loginState.data?.user.name}
                <a href="#" onClick={logout}>
                    <CIcon name="cil-account-logout" className="mx-2"/>
                </a>
                <a href="#" onClick={onClickInfo}>
                    <CIcon name="cil-info" className="mx-2"/>
                </a>
            </CHeaderNav>
        </CHeader>
    )
}

export default TheHeader;