import {GridApi} from "ag-grid-community";

import {ActionProps} from "../../../../components/gridAction";
import Grid, {ListaMultiplaFilter} from "../../../../components/grid";
import {numberDecimalFormat} from "../../../../components/numberBRLFormatForGrid";

import {AgGridColumn} from "ag-grid-react";
import React from "react";
import {
    MesList,
    mesListFormater,
    SituacaoFaturaList,
    situacaoFaturaListFormater, SituacaoFaturaTypes,
    SituacaoList,
    situacaoListFormater
} from "../../index";
import enumDescription from "../../../../services/enumDescription";


const FaturaList = () => {

    const actions: Array<ActionProps> = [
        {
            route:'fechamento',
            color:'success',
            type:'normal',
            title:'Calcular Fatura'
        },
        {
            route:'fatura/enviar',
            color:'primary',
            type:'normal',
            title:'Enviar para E-commerce'
        },
        {
            route:'fatura/cancelar',
            color:'danger',
            type:'post',
            title:'Cancelar Fatura'

        },
        {
            route:'fatura/visualizar',
            color:'info',
            type:'grid',
            title:'Detalhes'
        },
        {
            route:'fatura/delete',
            color:'danger',
            type:'delete',
            title:'Excluir'
        }

    ];

    const handleGridReady = (gridApi:GridApi)=>{
        const filterInstance = gridApi.getFilterInstance('situacao');
        filterInstance?.setModel({
            type: 'in',
            value: [SituacaoFaturaTypes.SITUACAO_EM_PROCESSAMENTO, SituacaoFaturaTypes.FECHADO,SituacaoFaturaTypes.FATURADO, SituacaoFaturaTypes.ENVIADO]
        });
        gridApi.onFilterChanged();
    }

    return (
        <Grid actions={actions} rota="fatura/list" handleGridReady={handleGridReady} >
            <AgGridColumn width={100}  headerName="#" checkboxSelection field="id"  sortable={ true } filter={ 'agNumberColumnFilter'}></AgGridColumn>
            <AgGridColumn width={150} sort="desc" headerName="Ano" field="ano"  sortable={ true } filter={ 'agNumberColumnFilter' } ></AgGridColumn>
            <AgGridColumn width={150} sort="desc" headerName="Mês" field="mes"  sortable={ true } valueFormatter={mesListFormater} filter={ FiltroMes } ></AgGridColumn>
            <AgGridColumn width={200} headerName="Situação" field="situacao"  sortable={ true } filter={ FiltroSituacao }  valueFormatter={situacaoFaturaListFormater}></AgGridColumn>
            <AgGridColumn flex={1} headerName="Cliente" field="pessoa/nome"  sortable={ true } filter={ true }></AgGridColumn>
            <AgGridColumn flex={1} headerName="Valor" field="valor" filter={ 'agNumberColumnFilter'} valueFormatter={numberDecimalFormat} sortable={ true } ></AgGridColumn>
        </Grid>
    );
}

class FiltroMes extends ListaMultiplaFilter{
    getList(): enumDescription {
        return MesList;
    }
}

class FiltroSituacao extends ListaMultiplaFilter{
    getList(): enumDescription {
        return SituacaoFaturaList;
    }
}

export default FaturaList;