import React, {useState, useEffect} from 'react';
import {getApi} from "../../apis/backendApis";
import {CButton, CInput} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import Select from "react-select";
import {listConfiguracaoAtivaFiltro, listRegimeTributacaoFiltro, listCheckoutConfig} from "./index";
import {cilCarAlt} from "@coreui/icons/js/free/cil-car-alt";
import useAlert from "../../services/useAlert";
import {useLocation} from "react-router-dom";

const ClientStatus = () => {
    const [listGateway, setListGateway] = useState([]);
    const [listFormaPagamento, setListFormaPagamento] = useState([]);
    const [listMarketplace, setListMarketplace] = useState([]);
    const [listFarm, setListFarm] = useState([]);
    const [dataGrid, setDataGrid] = useState([]);
    const [total, setTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterLoja, setFilterLoja] = useState('');
    const [showAlert] = useAlert();
    //filtros
    const [gateway, setGateway] = useState([]);
    const [formaPagamento, setFormaPagamento] = useState([]);
    const [marketplace, setMarketplace] = useState([]);
    const [regimeTributacao, setRegimeTributacao] = useState([]);
    const [doisFatores, setDoisFatores] = useState([]);
    const [apiFrete, setApiFrete] = useState([]);
    const [farms, setFarms] = useState([]);
    const [checkout, setCheckout] = useState([]);
    let location = useLocation();


    useEffect(() => {
        async function loadLists() {
            let api = getApi();
            api.get(`client-status/lists`).then((response) => {
                let data = response.data;
                setListGateway(getList(data['gatewayList']))
                setListMarketplace(getList(data['marketplaceList']))
                setListFormaPagamento(getList(data['formaPagamentoList']))
                setListFarm(getList(data['farmList']))
            }).finally(() => {
                //console.log('finally');
            });
        }

        loadLists();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            loadData();
        }, 200)
    }, []);

    async function loadData() {
        setLoading(true);
        setDataGrid([]);
        let api = getApi();
        let paramGateway: any = [];
        gateway.map((item: any) => {
            paramGateway.push(item.value);
        });
        let paramFormaPagamento: any = [];
        formaPagamento.map((item: any) => {
            paramFormaPagamento.push(item.value);
        });
        let paramMarketplace: any = [];
        marketplace.map((item: any) => {
            paramMarketplace.push(item.value);
        });
        let paramRegimeTributacao: any = [];
        regimeTributacao.map((item: any) => {
            paramRegimeTributacao.push(item.value);
        });

        api.get(`client-status/loadData`, {
            params: {
                'gateway': JSON.stringify(paramGateway),
                'formaPagamento': JSON.stringify(paramFormaPagamento),
                'marketplace': JSON.stringify(paramMarketplace),
                'regimeTributacao': JSON.stringify(paramRegimeTributacao),
                'loja': filterLoja,
                'apiFrete': apiFrete,
                'doisFatores': doisFatores,
                'farms': farms,
                'checkout': checkout
            }
        }).then((response) => {
            let data = response.data.rows;
            setDataGrid(data);
            setTotal(response.data.total);
            setLoading(false);
        }).finally(() => {

        });
    }

    const getList = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    const onClickConsultar = () => {
        loadData();
    }

    const handleChangeMarketplace = (option: any) => {
        setMarketplace(option);
    }

    const handleChangeGateway = (option: any) => {
        setGateway(option);
    }

    const handleChangeFormaPagamento = (option: any) => {
        setFormaPagamento(option);
    }

    const handleChangeRegime = (option: any) => {
        setRegimeTributacao(option);
    }

    const handleDoisFatores = (option: any) => {
        if (option) {
            setDoisFatores(option.value);
        } else {
            setDoisFatores([]);
        }
    }

    const handleInfraFarm = (option: any) => {
        if (option) {
            setFarms(option.value);
        } else {
            setFarms([]);
        }
    }

    const handleApiFrete = (option: any) => {
        if (option) {
            setApiFrete(option.value);
        } else {
            setApiFrete([]);
        }


    }

    const handleCheckout = (option: any) => {
        if (option) {
            setCheckout(option.value);
        } else {
            setCheckout([]);
        }
    }

    const handleChangeLoja = (event: any) => {
        setFilterLoja(event.target.value);
    }

    const onKeyDownConsulta = (event: any) => {
        if (event.code == 'Enter') {
            onClickConsultar();
        }
    }

    const refreshStatusClient = (idCliente: any) => {
        setLoading(true);
        let api = getApi();
        api.get(`client-status/update/` + idCliente).then((response) => {
            if (response.data['success']) {
                loadData();
                showAlert({
                    msg: response.data['mensagem'],
                    toaster: true,
                    type: 'success'
                });
            } else {
                showAlert({
                    title: "Erro ao atualizar Registro",
                    msg: response.data['mensagem'],
                    toaster: false,
                    type: 'danger'
                });
            }
            setLoading(false);
        }).catch((error) => {
            let urlSplit = document.URL.split('client-status');
            urlSplit = urlSplit.map(part => part.replace(',', ''))
            let newUrl = urlSplit.join('');
            let requestUrl = '';
            if (error.response) {
                requestUrl = error.response.config.url;
                const responseData = error.response.data;
                if (responseData.error) {
                    const errorMessage = responseData.error;
                    showAlert({
                        title: 'Algo deu errado!',
                        type: 'danger',
                        msg: `URL:` + newUrl+`${requestUrl}\nErro: ${errorMessage}`,
                    });
                } else {
                    showAlert({
                        title: 'Algo deu errado!',
                        type: 'danger',
                        msg: `URL:` + newUrl+`${requestUrl}\nErro: ${error.toString()}`,
                    });
                }
            } else if (error.request) {
                requestUrl = error.request.config.url;
                showAlert({
                    title: 'Algo deu errado!',
                    type: 'danger',
                    msg: `URL:` + newUrl+`${requestUrl}\nErro: ${error.toString()}`,
                });
            } else {
                showAlert({
                    title: 'Algo deu errado!',
                    type: 'danger',
                    msg: `Erro: ${error.toString()}`,
                });
            }
        });
    }

    return (
        <>
            <div className={'container-client-status'}>
                <div className={'header-filter-client-status'}>
                    <div className="">
                        <div className="row">
                            <div className="col-11 row">
                                <div className="col-3 pb-1"><Select
                                    options={listGateway}
                                    isMulti
                                    onChange={handleChangeGateway}
                                    placeholder={'Gateway de Pagamento'}
                                    closeMenuOnSelect={false}
                                    className={'filtro-select'}
                                ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listFormaPagamento}
                                        isMulti
                                        onChange={handleChangeFormaPagamento}
                                        placeholder={'Forma de Pagamento'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listMarketplace}
                                        isMulti
                                        onChange={handleChangeMarketplace}
                                        placeholder={'Marketplace'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listRegimeTributacaoFiltro}
                                        isMulti
                                        onChange={handleChangeRegime}
                                        placeholder={'Regime de Tributação'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listConfiguracaoAtivaFiltro}
                                        onChange={handleDoisFatores}
                                        placeholder={'Login Dois Fatores (Admin)'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                        isSearchable={false}
                                        isClearable={true}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listConfiguracaoAtivaFiltro}
                                        onChange={handleApiFrete}
                                        placeholder={'Api de Frete (Logística Clientes)'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                        isSearchable={false}
                                        isClearable={true}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        className={'filtro-select'}
                                        options={listFarm}
                                        onChange={handleInfraFarm}
                                        placeholder={'Farms'}
                                        closeMenuOnSelect={true}
                                        isSearchable={true}
                                        isClearable={true}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <Select
                                        options={listCheckoutConfig}
                                        onChange={handleCheckout}
                                        placeholder={'Checkout'}
                                        closeMenuOnSelect={false}
                                        className={'filtro-select'}
                                        isClearable={true}
                                    ></Select>
                                </div>
                                <div className="col-3 pb-1">
                                    <CInput
                                        style={{height: "37px"}}
                                        className={'filtro-select'}
                                        placeholder={'Nome da Loja'}
                                        value={filterLoja}
                                        onChange={handleChangeLoja}
                                        onKeyDown={onKeyDownConsulta}
                                    ></CInput>
                                </div>
                            </div>
                            <div className="col-1 d-flex align-items-center justify-content-center pb-1">
                                <CButton className={'btn btn-info d-flex align-items-center'}
                                         onClick={onClickConsultar}>
                                    <CIcon name="cil-reload"></CIcon>&nbsp;Atualizar
                                </CButton>
                            </div>
                        </div>
                    </div>
                    <div className={'header-filter-client-status-row'}>
                    </div>
                </div>
                <div className={'grid-filter-client-status'}>
                    {(dataGrid.length == 0 && !loading) && (
                        <div className={'grid-client-sem-registro'}>Não localizado nenhum registro</div>
                    )}
                    {loading && (
                        <div className={'grid-client-loading'}>
                            Carregando Dados...
                        </div>
                    )}
                    {!loading && (
                        dataGrid.map((cliente: any, keyClient: any) => {
                            return <div className={'row-client-status'} key={keyClient}>
                                <div className={'client-info'}>
                                    <div className={'cliente-descricao'}>
                                        <a target={'_blank'}
                                           href={'http://' + cliente.url}>#{cliente.id} {cliente.nome}</a>
                                    </div>
                                    <div className={'cliente-descricao-configuracao'}>
                                        {(cliente['dois_fatores']) && (
                                            <div title='Login Dois Fatores ativo'>
                                                <CIcon className={'text-success'} name="cil-shield-alt"></CIcon>
                                            </div>
                                        )}
                                        {(cliente['dois_fatores'] == false) && (
                                            <div title='Login Dois Fatores Inativo'>
                                                <CIcon className={'text-danger'} name="cil-shield-alt"></CIcon>
                                            </div>
                                        )}
                                        {(cliente['api_frete_panoverse']) && (
                                            <div title='Utilizando a API de Logística'>
                                                <CIcon className={'text-success'} name="cil-check"></CIcon>
                                            </div>
                                        )}
                                        {(cliente['lojas']) && (
                                            <div title={
                                                'Farm: ' + cliente['infra']['farm'] + '\n' +
                                                cliente['lojas'].map((loja: any) => {
                                                    if (loja['infra']) {
                                                        let lojaInfo = loja['nome'] + ':\n';
                                                        Object.keys(loja['infra']).forEach((keyObj) => {
                                                            lojaInfo += '      ' + keyObj + ': ' + loja['infra'][keyObj] + '\n';
                                                        });
                                                        return lojaInfo;
                                                    }
                                                    return '';
                                                })}>
                                                <CIcon className={'text-warning'} name="cil-apps"></CIcon>
                                            </div>
                                        )}
                                        <div className='small'>Atualizado em: {cliente.dataHora}</div>
                                    </div>
                                    {cliente['faturamento'].map((faturamento: any) => {
                                        return <div
                                            className={'small'}>{faturamento['cnpj']} - {faturamento['regime_tributacao']} em {faturamento['ambiente_autorizacao']}</div>
                                    })}
                                </div>
                                <div className={'client-info-conf loja'}>
                                    {cliente['lojas'].map((loja: any) => {
                                        return <div className={'client-info-item'}><a target={'_blank'}
                                                                                      href={loja['url']}>{loja['nome']}</a>
                                            <div className={'text-value-sm font-italic'} title={'Versão checkout'}>
                                                <CIcon className={'cil-arrow-left'} name="cil-basket"/> &nbsp;
                                                {listCheckoutConfig.find((config: any) => config.value === loja['infra']['checkoutVersion'])?.label}
                                            </div>
                                        </div>
                                    })}
                                </div>

                                <div className={'client-info-conf'}>
                                    {cliente['pagamentos'].map((pagamento: any) => {
                                        if (pagamento.ativo) {
                                            return <div
                                                className={'client-info-item'}>{pagamento['formaPagamentoNome']} ({pagamento['gatewayNome']})</div>
                                        }
                                    })}
                                    {cliente['pagamentos'].map((pagamento: any) => {
                                        if (!pagamento.ativo) {
                                            return <div
                                                className={'client-info-item inativo'}>{pagamento['formaPagamentoNome']} ({pagamento['gatewayNome']})</div>
                                        }
                                    })}

                                </div>
                                <div className={'client-info-conf marketplace'}>
                                    {cliente['marketplaces'].map((pagamento: any) => {
                                        return <div className={'client-info-item'}>{pagamento['nome']}</div>
                                    })}
                                </div>
                                <div className={'col-1 align-content-center text-center'}>
                                    <CButton className={'btn-instagram'}
                                             onClick={() => refreshStatusClient(cliente.id)}
                                             title={"Atualizar Informações do Cliente " + cliente.id}><CIcon
                                        name="cil-reload"></CIcon></CButton>
                                </div>
                            </div>
                        })
                    )}
                </div>
                <div className={'footer-filter-client-status'}>
                    <div><strong>Total de Registros:</strong> {total}</div>
                </div>
            </div>
        </>
    )
}
export default ClientStatus;