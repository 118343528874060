import {ContentRoute} from "../../../models/Route";
import ResultadoList from "./resultadoList";
import ResumoDashboard from "./resumoDashboard";
import FechamentoForm from "./fechamento/fechamentoForm";
import FaturaList from "./fechamento/faturaList";
import FaturaForm from "./fechamento/FaturaForm";
import RemeterFaturaForm from "./fechamento/remeterFaturaForm";


export  interface FilterDate {
    value: number;
    description: string;
}

export enum SituacaoTypes {
    SITUACAO_EM_ABERTO = 1,
    SITUACAO_FECHADO = 2,
    SITUACAO_FATURADO = 3,
    SITUACAO_PAGO = 4,
    SITUACAO_ENVIADO = 5
}

export enum ValueFilter {
    MAIOR = 1,
    MENOR = 2,
    ENTRE = 3,
}

// Definição dos filtros
export const filtersDate: FilterDate[] = [
    { value: ValueFilter.MAIOR, description: 'Maior Igual' },
    { value: ValueFilter.MENOR, description: 'Menor Igual' },
    { value: ValueFilter.ENTRE, description: 'Entre' },
];

export const financeiro_content_routes: Array<ContentRoute> = [
    {
        'path': '/financeiro/fatura',
        'description': 'Faturas de Clientes',
        'exact': true,
        'component': FaturaList,
        'menu': true
    },
    {
        'path': '/financeiro/resultado',
        'description': 'Itens de Fatura',
        'exact': true,
        'component': ResultadoList,
        'menu': true
    },
    {
        'path': '/financeiro/resumo',
        'description': 'Dashboard',
        'exact': true,
        'component': ResumoDashboard,
        'menu': true
    },
    {
        'path': '/financeiro/fechamento',
        'description': 'Resumo',
        'exact': true,
        'component': FechamentoForm,
        'menu': false
    },
    {
        'path': '/financeiro/fatura/visualizar/:id',
        'description': 'detalhes',
        'exact': true,
        'component': FaturaForm,
        'menu': false
    },
    {
        'path': '/financeiro/fatura/enviar',
        'description': 'detalhes',
        'exact': true,
        'component': RemeterFaturaForm,
        'menu': false
    },
]

export const optionsFaturas = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Faturas Pagas x Pendentes'
        },
    },
};

export const optionsClienteFaturas = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
    }
};

export const ResponseDataResume = (data: any) => {
    const naoPago = data['nao-pago'] || []; // Se não existir, inicializa como array vazio
    const pago = data['pago'] || []; // Se não existir, inicializa como array vazio

    const mergeData = (naoPagos: any[], pagos: any[]) => {
        const mergedData: { [key: string]: any } = {};
        naoPagos.forEach((item: any) => {
            const { competencia_label, valortotal } = item;
            if (!mergedData[competencia_label]) {
                mergedData[competencia_label] = { competencia_label, naoPago: 0, pago: 0 };
            }
            mergedData[competencia_label].naoPago += parseFloat(valortotal);
        });
        pagos.forEach((item: any) => {
            const { competencia_label, valortotal } = item;
            if (!mergedData[competencia_label]) {
                mergedData[competencia_label] = { competencia_label, naoPago: 0, pago: 0 };
            }
            mergedData[competencia_label].pago += parseFloat(valortotal);
        });

        return Object.values(mergedData);
    };

    let mergedData = mergeData(naoPago, pago);
    mergedData = mergedData.sort((a: any, b: any) => {
        const [aYear, aMonth] = a.competencia_label.split('/').map(Number);
        const [bYear, bMonth] = b.competencia_label.split('/').map(Number);
        if (aYear !== bYear) {
            return aYear - bYear;
        }
        return aMonth - bMonth;
    });

    const labels = mergedData.map((item: any) => item.competencia_label);
    const naoPagoData = mergedData.map((item: any) => item.naoPago);
    const pagoData = mergedData.map((item: any) => item.pago);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Pendentes',
                data: naoPagoData,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Pago',
                data: pagoData,
                borderColor: 'rgba(8,255,0,0.38)',
                backgroundColor: 'rgba(0,255,35,0.5)',
            },
        ],
    };

    return chartData;
};


export const dataPie = (data: any) => {
    const clientes: any[] = data.clientes.map((cliente: any) => {return cliente.cliente})
    const valorTotal: any[] = data.clientes.map((valor: any) => {return valor.valorTotal})
    return {
        labels: clientes,
        datasets: [
            {
                label: '# of Votes',
                data: valorTotal,
                backgroundColor: [
                    'rgba(0,3,255,0.84)',
                    'rgba(0, 128, 255, 0.85)',
                    'rgba(0, 255, 255, 0.75)',
                    'rgba(0, 255, 128, 0.65)',
                    'rgba(255,255,0,0.58)',
                    'rgba(255,204,0,0.59)',
                    'rgba(255,153,0,0.6)',
                    'rgba(255,102,0,0.63)',
                    'rgba(255,51,0,0.34)',
                    'rgba(255,0,0,0.6)'
                ],
                borderColor: [
                    'rgba(0, 0, 255, 1)',
                    'rgba(0, 128, 255, 1)',
                    'rgba(0, 255, 255, 1)',
                    'rgba(0, 255, 128, 1)',
                    'rgba(255, 255, 0, 1)',
                    'rgba(255, 204, 0, 1)',
                    'rgba(255, 153, 0, 1)',
                    'rgba(255, 102, 0, 1)',
                    'rgba(255, 51, 0, 1)',
                    'rgba(255, 0, 0, 1)'
                ],
                borderWidth: 1,
            },
        ],
    }
};