import React, {useEffect, useState} from 'react';
import Form, {FormApi} from "../../../../components/form";
import useCustomForm from '../../../../services/useCustomForm'
import {getApi} from "../../../../apis/backendApis";
import Select from "react-select";
import {
    ContractObjetList,
    SituacaoClienteObjContratoList,
    SituacaoClienteObjContratoTypes,
    typesContractObjects
} from "../index";
import {CButton, CCard, CFormGroup, CInput, CLabel, CSelect, CTextarea} from "@coreui/react";
import {TipoConfigTypes} from "../../../contratos/objetocontrato";
import {NumericFormat} from 'react-number-format';
import useCurrentAction from "../../../../services/useCurrentAction";
import CIcon from "@coreui/icons-react";
import {remove} from "js-cookie";

const ClienteObjetoContratoForm = () => {

    const initialValuesObjCon: any = [{}];

    const [formApi, setFormApi] = useState<FormApi>();
    const [listObjetosContratos, setListObjetosContratos] = useState([])
    const [objetoContrato, setObjetoContrato] = useState(initialValuesObjCon)
    const action = useCurrentAction();

    const initialValues: any = {
        objetoContrato: '',
        tipo: 0,
        configuracao: {},
        situacao: '',
        dataHoraInicio: '',
        dataHoraFim: '',
    };

    const initialValuesConfiguracaoComissao = [{
        valorFaturamento: null,
        percentualLoja: '',
        percentualMarketplace: '',
        comissaoB2B: ''
    }];

    const initialValuesConfiguracaoSobDemanda = [{
        quantidade: '',
        precoUnidade: ''
    }];

    const initialValuesValorFixo = [{
        valorFixo: '',
    }];


    const isView = () => {
        return action === 'view';
    };

    const isEdit = () => {
        return action === 'edit';
    };

    const {

        setValues,
        values,
        handleSubmit,
        handleSubmitValues,
        handleChange,
    } = useCustomForm(
        {
            initialValues,
            route: 'cliente-objeto-contrato',
            onSubmit: values => {
                let newValues = {...values.values};
                if (isEdit()) {
                    delete newValues.configuracaoCalculo
                    newValues['objetoContrato'] = newValues['objetoContrato']['id'];
                    newValues['cliente'] = newValues['cliente']['id'];
                }
                handleSubmitValues(newValues, values.filesUpload)
            },
        }
    );

    const getList = (list: any) => {
        let data: any = [];
        (Object.keys(list) as (keyof typeof list)[]).forEach((key, index) => {
            let item: any = {};
            item['label'] = list[key];
            item['value'] = key;
            data.push(item);
        });
        return data;
    }

    useEffect(() => {
        async function loadLists() {
            let api = getApi();
            api.get(`cliente-objeto-contrato/suggest`).then((response) => {
                let data = response.data;
                setListObjetosContratos(getList(data['objetosContratos']))
            }).finally(() => {

            });
        }
        loadLists();
    }, []);


    const handleFormReady = (formApi: FormApi) => {
        setFormApi(formApi);
    }

    const handleObjetoContrato = (option: any) => {
        values.objetoContrato = option.value
        if (values.tipo) {
            values.tipo = '';
            values.configuracao = {};
        }
        setValues({...values})
        let api = getApi();
        api.get(`objeto-contrato/get/` + values.objetoContrato).then((response) => {
            setObjetoContrato(response.data)
        }).finally(() => {

        });

    }


    const handleClienteObjetoContratoTipo = (event: any) => {
        if (values.objetoContrato) {
            let val1 = event.target.value
            values.tipo = parseInt(val1)
            if (val1 == typesContractObjects.AJUSTE_COMERCIAL) {
                switch (objetoContrato['tipoConfiguracao']) {
                    case TipoConfigTypes.SOB_DEMANDA:
                        values.configuracao = {};
                        values.configuracao['valoresDemanda'] = objetoContrato['configuracao'] || [];
                        break;
                    case TipoConfigTypes.TABELA_COMISSAO:
                        values.configuracao = objetoContrato['configuracao']
                        break;
                    case TipoConfigTypes.VALOR_FIXO:
                        values.configuracao = {};
                        values.configuracao['valorFixo'] = objetoContrato['configuracao'] || [];
                        break;
                }
                values.configuracao.tipoObjContrato = objetoContrato['tipoConfiguracao']
            } else {
                values.configuracao = {};
            }
            setValues({...values})
        } else {
            alert('Selecione um Objeto de Contrato')
        }
    }
    const handleSituacao = (event: any) => {
        let val1 = event.target.value
        values.situacao = parseInt(val1)
        setValues({...values})
    }

    const addRow = () => {
        switch (values.configuracao['tipoObjContrato']) {
            case TipoConfigTypes.TABELA_COMISSAO:
                values['configuracao']['tabelaComissao'].push(initialValuesConfiguracaoComissao[0]);
                break;
            case TipoConfigTypes.SOB_DEMANDA:
                values['configuracao']['valoresDemanda'].push(initialValuesConfiguracaoSobDemanda[0]);
                break;
            case TipoConfigTypes.VALOR_FIXO:
                values['configuracao']['valorFixo'].push(initialValuesValorFixo[0]);
                break;
        }
        setValues({...values})
    }

    const lessRow = (index: any, allRow: boolean) => {
        if (values.configuracao.length - 1 != 0) {
            switch (values.configuracao['tipoObjContrato']) {
                case TipoConfigTypes.TABELA_COMISSAO:
                    if (!allRow) {
                        if (values.configuracao['tabelaComissao'].length - 1 > 0) {
                            values['configuracao']['tabelaComissao'].splice(index, 1);
                        }
                    } else {
                        values['configuracao']['tabelaComissao'].splice(1);
                    }
                    break;
                case TipoConfigTypes.SOB_DEMANDA:
                    if (!allRow) {
                        if
                        (values.configuracao['valoresDemanda'].length - 1 > 0) {
                            values['configuracao']['valoresDemanda'].splice(index, 1);
                        }
                    } else {
                        values['configuracao']['valoresDemanda'].splice(1);
                    }
                    break;
            }
            setValues({...values});
        }
    }

    const updateConfigValuesMinValue = (fieldName: string, value: any) => {
        values.configuracao[fieldName] = value;
        setValues({...values});
    };

    const updateConfigValues = (row: number, fieldName: string, value: any) => {
        values.configuracao['tabelaComissao'][row][fieldName] = value;
        setValues({...values});
    };

    const updateConfigValuesDemanda = (row: number, fieldName: string, value: any) => {
        values.configuracao['valoresDemanda'][row][fieldName] = value;
        setValues({...values});
    };

    const updateConfigValuesFix = (row: number, fieldName: string, value: any) => {
        values.configuracao['valorFixo'][row][fieldName] = value;
        setValues({ ...values });
    };

    const updateConfigValuesValorSetup = (value: any) => {
        values.configuracao['valorSetup'] = value;
        setValues({ ...values });
    };

    const handleInput = (event: any) => {

    }

    return (
        <Form entity="cliente-objeto-contrato" handleSubmit={handleSubmit} onFormReady={handleFormReady}>
            {console.log(values)}
            <CFormGroup>
                <CLabel className={'text-danger'}>Selecione um objeto de contrato</CLabel>
                <Select
                    required
                    isDisabled={isView() || isEdit()}
                    options={listObjetosContratos}
                    onChange={handleObjetoContrato}
                    placeholder={'Objetos de Contrato'}
                    closeMenuOnSelect={true}
                    className={'filtro-select'}
                    value={listObjetosContratos.find((ObjContrato: any) => ObjContrato.value == values['objetoContrato']['id'])}

                ></Select>
            </CFormGroup>

            <CFormGroup>
                <CLabel>Selecione a situação</CLabel>
                <CSelect
                    readOnly={isView()}
                    disabled={isView()}
                    required
                    placeholder={'Tipo'}
                    className={'filtro-select'}
                    value={values.situacao}
                    onChange={handleSituacao}
                >
                    <option value={''}>Selecione...</option>
                    {SituacaoClienteObjContratoList.getList().map((item: any) => (
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>

            <CFormGroup>
                <CLabel className={''}>Observação: </CLabel>
                <CTextarea
                    disabled={isView() }
                    onChange={handleChange}
                    className={'filtro-select'}
                    name={'observacao'}
                    value={values?.observacao}
                ></CTextarea>
            </CFormGroup>

            <CFormGroup className={'row'}>
                <div className={'col text-danger'}>
                    <CLabel htmlFor="dataHoraInicio">Data/Hora Inicio</CLabel>
                    <CInput required readOnly={isView()} disabled={isView()} type="datetime-local" id="dataHoraInicio"
                            name="dataHoraInicio" onChange={handleChange} value={values.dataHoraInicio}/>
                </div>
                <div className={'col'}>
                    <CLabel htmlFor="dataHoraFim">Data/Hora Fim</CLabel>
                    <CInput readOnly={isView()} disabled={isView()} type="datetime-local" id="dataHoraFim"
                            name="dataHoraFim" onChange={handleChange} onInput={handleInput}
                            value={values.dataHoraFim || ''}/>
                </div>
            </CFormGroup>
            <CFormGroup>
                <CLabel className={'text-danger'}>Selecione um tipo</CLabel>
                <CSelect
                    required
                    placeholder={'Tipo'}
                    className={'filtro-select'}
                    value={values.tipo}
                    readOnly={isView() || isEdit()}
                    disabled={isView() || isEdit()}
                    onChange={handleClienteObjetoContratoTipo}
                >
                    <option value={''}>Selecione...</option>
                    {ContractObjetList.getList().map((item: any) => (
                        <option key={item.value} value={item.value}>{item.description}</option>
                    ))}
                </CSelect>
            </CFormGroup>
            {values.tipo == typesContractObjects.AJUSTE_COMERCIAL &&
                <CFormGroup>
                    {values['configuracao']['tipoObjContrato'] == TipoConfigTypes.TABELA_COMISSAO &&
                        <CCard>
                            <div className={'row p-1 mb-2'}>
                                <div className={'col-12 p-3'}>
                                    <CLabel htmlFor="ValorMínimo">Valor Mínimo</CLabel>
                                    <NumericFormat
                                        required
                                        name={"valorMinimo"}
                                        id={'valorMinimo'}
                                        placeholder={"R$"}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        customInput={CInput}
                                        value={isView() || isEdit() ? values.configuracao['valorMinimo'] : objetoContrato.configuracao['valorMinimo']}
                                        onBlur={(e) => {
                                            const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                            updateConfigValuesMinValue('valorMinimo', valorSemSeparadores);
                                        }}
                                        readOnly={isView()}
                                        disabled={isView()}
                                    />
                                </div>
                            </div>
                            <div className={"row ml-1 "}>
                                <div className={'col border font-weight-bold '}>
                                    <CLabel className={'p-1 m-0'} htmlFor="Faturamento">Faturamento</CLabel>
                                </div>
                                <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                    <CLabel className={'p-1 m-0'}
                                            htmlFor="Faturamento">Custo Loja (%)</CLabel>
                                </div>
                                <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                    <CLabel className={'p-1 m-0'}
                                            htmlFor="Faturamento">Custo Marketplace (%)</CLabel>
                                </div>
                                <div className={'col border-top font-weight-bold border-right border-bottom'}>
                                    <CLabel className={'p-1 m-0'}
                                            htmlFor="Faturamento">Comissão B2B</CLabel>
                                </div>
                                <div style={{width: "200px"}}>
                                    {!isView() &&
                                        <div className="d-flex  flex-row justify-content-center p-1">
                                            <CButton onClick={() => lessRow(Math.random(), true)}
                                                     color="light"
                                                     className=" btn-sm ">Apagar todas as linhas
                                            </CButton>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={'pb-1'}>
                                {values['configuracao'] && values['configuracao']['tabelaComissao'].map((configuracao: any, index: number) => (
                                    <div key={index} className={"row ml-1"}>
                                        <div className={'col border-right border-left '}>
                                            <NumericFormat
                                                className={'mt-1'}
                                                name={"valorFaturamento"}
                                                id={'valorFaturamento'}
                                                placeholder={"R$"}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                customInput={CInput}
                                                value={configuracao.valorFaturamento}
                                                onBlur={(e) => {
                                                    const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValues(index, 'valorFaturamento', valorSemSeparadores);
                                                }}
                                                readOnly={isView()}
                                                disabled={isView()}
                                            />
                                        </div>
                                        <div className={'col border-right'}>
                                            <NumericFormat
                                                className={'mt-1'}
                                                placeholder={"%"}
                                                id="configCustoLoja"
                                                name={'configCustoLoja'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                customInput={CInput}
                                                onBlur={(e) => {
                                                    const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValues(index, 'percentualLoja', valorSemSeparadores)
                                                }}
                                                value={configuracao['percentualLoja']}
                                                readOnly={isView()}
                                                disabled={isView()}
                                            />
                                        </div>
                                        <div className={'col border-right'}>
                                            <NumericFormat
                                                className={'mt-1'}
                                                placeholder={"%"}
                                                id="configCustoMarketplace"
                                                name={'configCustoMarketplace'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                customInput={CInput}
                                                onBlur={(e) => {
                                                    const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValues(index, 'percentualMarketplace', valorSemSeparadores)
                                                }}
                                                value={configuracao['percentualMarketplace']}
                                                readOnly={isView()}
                                                disabled={isView()}/>
                                        </div>
                                        <div className={'col border-right'}>
                                            <NumericFormat
                                                className={'mt-1'}
                                                placeholder={"%"}
                                                id="comissao2b2"
                                                name={'comissao2b2'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                customInput={CInput}
                                                onBlur={(e) => {
                                                    const valorSemSeparadores = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValues(index, 'comissao2b2', valorSemSeparadores)
                                                }}
                                                value={configuracao['comissao2b2']}
                                                readOnly={isView()}
                                                disabled={isView()}/>
                                        </div>
                                        <div style={{width: "200px"}} className={''}>
                                            {!isView() &&
                                                <div className="mt-1 d-flex flex-row justify-content-center p-1">
                                                    <CButton onClick={addRow} color="light"
                                                             className="mr-1 small btn-sm"><CIcon
                                                        name="cil-plus"/></CButton>
                                                    <CButton onClick={() => lessRow(index, false)} color="light"
                                                             className="small btn-sm"><CIcon
                                                        name="cil-minus"/></CButton>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </CCard>
                    }
                    {values['configuracao']['tipoObjContrato'] == TipoConfigTypes.SOB_DEMANDA &&
                        <CCard className={"mt-4 pl-3"}>
                            <div className={"row"}>
                                <div className={'col font-weight-bold border-right border-bottom'}>
                                    <CLabel className={'p-1 m-0'} htmlFor="quantidade">A cada quantidade de</CLabel>
                                </div>
                                <div className={'col font-weight-bold border-bottom border-right'}>
                                    <CLabel className={'p-1 m-0'} htmlFor="quantidade">Paga-se o valor</CLabel>
                                </div>
                                <div style={{width: "200px"}}>
                                    {!isView() &&
                                        <div className="d-flex flex-row justify-content-center p-1">
                                            <CButton onClick={() => lessRow(Math.random(), true)}
                                                     color="light"
                                                     className=" btn-sm ">Apagar todas as linhas
                                            </CButton>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={'pb-1'}>
                                {values['configuracao'] && values.configuracao['valoresDemanda'].map((configuracao: any, index: number) => (
                                    <div key={index} className={"row"}>
                                        <div className={'col border-right'}>
                                            <NumericFormat
                                                className={'mt-1'}
                                                readOnly={isView()}
                                                disabled={isView()}
                                                customInput={CInput}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={0}
                                                fixedDecimalScale={false}
                                                id="configSobDemanda"
                                                name={'valorSetup'}
                                                // @ts-ignore
                                                onBlur={(e) => {
                                                    let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValuesDemanda(index, 'quantidade', val)
                                                }}
                                                value={configuracao.quantidade}/>
                                        </div>
                                        <div className={'col border-right'}>
                                            <NumericFormat
                                                name={"precoUnidade"}
                                                id={'precoUnidade'}
                                                placeholder={"0,00"}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                customInput={CInput}
                                                value={configuracao['precoUnidade']}
                                                onBlur={(e) => {
                                                    let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                                    updateConfigValuesDemanda(index, 'precoUnidade', val);
                                                }}
                                                readOnly={isView()}
                                                disabled={isView()}
                                                className={'mt-1'}
                                            />

                                        </div>
                                        <div style={{width: '200px'}}>
                                            {!isView() &&
                                                <div className="d-flex flex-row justify-content-center">
                                                    <CButton onClick={addRow} color="light"
                                                             className="mr-1 small btn-sm"><CIcon
                                                        name="cil-plus"/></CButton>
                                                    <CButton onClick={() => lessRow(index, false)} color="light"
                                                             className="small btn-sm"><CIcon
                                                        name="cil-minus"/></CButton>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </CCard>
                    }
                    {values['configuracao']['tipoObjContrato'] == TipoConfigTypes.VALOR_FIXO &&
                        values.configuracao && values.configuracao['valorFixo'].map((configuracao: any, index: number) => (
                            <NumericFormat
                                key ={index}
                                name={"valorFixo"}
                                id={'valorFixo'}
                                placeholder={"0,00"}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                customInput={CInput}
                                value={configuracao['valorFixo']}
                                onBlur={(e) => {
                                    let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                    updateConfigValuesFix(index, 'valorFixo', val);
                                }}
                                readOnly={isView()}
                                disabled={isView()}
                                className={'mt-1'}
                            />
                        ))
                    }
                    {values['configuracao']['tipoObjContrato'] == TipoConfigTypes.SEM_VALOR_MENSAL && (
                        <CFormGroup>
                            <CLabel className={'text-danger'}>Alterar Valor de Setup</CLabel>
                            <NumericFormat
                                name={"valorSetup"}
                                id={'valorSetup'}
                                placeholder={"0,00"}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={true}
                                customInput={CInput}
                                value={values.configuracao['valorSetup']}
                                onBlur={(e) => {
                                    let val = e.target.value.replace(/\./g, '').replace(/,/g, '.');
                                    updateConfigValuesValorSetup(val);
                                }}
                                readOnly={isView()}
                                disabled={isView()}
                                className={'mt-1'}
                            />
                        </CFormGroup>
                    )
                    }
                </CFormGroup>
            }
        </Form>
    )
}

export default ClienteObjetoContratoForm;